import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "description",
        label: "Description",
        components: [
          {
            type: "textfield",
            input: true,
            label:
              "<p>This widget allows you to select which component(s) of our mental status exam you would like to display.  The selections from each time you use the widget for a particular client will carry over to any other subsequent time it’s used.</p><p><em>Please note that we recommend that you do not place this widget within any layout where the sections are divided due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input",
          },
          {
            type: "checkbox",
            label: "General Appearance",
            weight: 6,
            key: "generalAppearance",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Attitude Behavior",
            weight: 8,
            key: "attitudeBehavior",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Sensorium",
            weight: 8,
            key: "sensorium",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Psychomotor and Musculoskeletal Activity",
            weight: 9,
            key: "psychomotor",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Mood (per client’s own words)",
            weight: 10,
            key: "mood",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Affect",
            weight: 11,
            key: "affect",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Speech/Language",
            weight: 12,
            key: "speechLanguage",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Though Processes",
            weight: 14,
            key: "thoughtProcesses",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Thought Content",
            weight: 14,
            key: "thoughtContent",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Perceptions",
            weight: 14,
            key: "perceptions",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Suicidal Thoughts",
            weight: 14,
            key: "sucidialThoughts",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Homicidal Thoughts",
            weight: 14,
            key: "homicidalThoughts",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Capacity of ADLs",
            weight: 14,
            key: "capacityOfAdls",
            customClass: "bold",
          },

          {
            type: "checkbox",
            label: "Orientations",
            weight: 14,
            key: "orientations",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Concentration and Attention (18+)",
            weight: 14,
            key: "concentrationAndAttention",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "WORLD spelled backwards (18+)",
            weight: 14,
            key: "worldSpell",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Memory Immediate: Digit Span Repetition (18+)",
            weight: 14,
            key: "memoryImmediate",
            customClass: "bold",
          },

          {
            type: "checkbox",
            label: "Insight (18+)",
            weight: 14,
            key: "insightGood",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Judgment (18+)",
            weight: 14,
            key: "judgment",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Intelligence (18+)",
            weight: 14,
            key: "intelligence",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Memory: Recent (18+)",
            weight: 14,
            key: "memoryRecent",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Memory: Remote (18+)",
            weight: 14,
            key: "memoryRemote",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Assessment of Client’s Memory (18+)",
            weight: 14,
            key: "patientAssessment",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Concentration and Attention (child/adol)",
            weight: 14,
            key: "concentration",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Memory (child/adol)",
            weight: 14,
            key: "memory",
            customClass: "bold",
          },
          {
            type: "checkbox",
            label: "Fund of Knowledge (child/adol)",
            weight: 14,
            key: "fundOfKnowledge",
            customClass: "bold",
          },
        ],
      },
      {
        key: "display",
        ignore: true,
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "api",
        ignore: true,
      },
      {
        key: "conditional",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        key: "layout",
        ignore: true,
      },
    ],
    ...extend
  );
};
