import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "description",
        label: "Description",
        components: [
          {
            type: "textfield",
            input: true,
            label: "<p>This widget allows you to include the latest set of vitals signs to your form.  You can also further update the vitals through the widget.</p><p><em>Please note that we recommend that you do not place the Vitals widget within any layout where the sections are divided due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input"
          }
        ]
      },
      {
        key: "display",
        ignore: true
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
