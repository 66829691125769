import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./PastOutpatientPsychiatricHistory.settingsForm";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const PastOutpatientComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pastOutpatient: props.value || ""
    };
  }

  onChangeEvent(event) {
    const pastOutpatient = event.target.value;
    this.setState({ pastOutpatient }, () => {
      this.props.onChange(this.state.pastOutpatient);
    });
  }

  getValue() {
    return this.state.pastOutpatient;
  }

  setValue(value) {
    this.setState({ pastOutpatient: value || "" });
  }

  render() {
    $(".component-edit-container p.lead").html("Past Outpatient Widget");
    return (
      <div id={this.props.component.key}>
        <div className="past-outpatient-widget">
          <div id="past-outpatient">
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <label className="control-label bold premium-color">
                      PAST OUTPATIENT PSYCHIATRIC HISTORY: Prior Treatment,
                      Precipitating Factors, Diagnosis, Course of Treatment
                    </label>
                  </div>
                  <span className="control-label bold premium-color ai-suggestion">AI</span>
                </div>
                <div className="form-group">
                  <textarea
                    className="w-100 bg-white"
                    style={{ resize: "none" }}
                    id="pastOutpatientCompInput"
                    rows={3}
                    value={this.state.pastOutpatient}
                    onChange={(event) => this.onChangeEvent(event)}
                    onBlur={(event) => this.onChangeEvent(event)}
                    onKeyUp={(event) => this.onChangeEvent(event)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class PastOutpatient extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */
  static get builderInfo() {
    return {
      title: "Past Outpatient History",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: PastOutpatient.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "pastOutpatientComp",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <PastOutpatientComp
        component={this.component}
        value={this.dataValue}
        onChange={this.updateValue}
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }


  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }
}
