import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./ProgressTowardsGoals.settingsForm";

export default class ProgressTowardsGoals extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Progress Towards Goals",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: ProgressTowardsGoals.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "progressTowardsGoalsComp"
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Progress Towards Goals");
    let content = `<select class="form-control" id="${this.component.key}" ref="${this.component.key}">`;

    content += `<option selected disabled>Select</option>`;
    content += `<option value="Regressed">Regressed</option>`;
    content += `<option value="No Progress">No Progress</option>`;
    content += `<option value="Minor Progress">Minor Progress</option>`;
    content += `<option value="Moderate">Moderate</option>`;
    content += `<option value="Significant Progress">Significant Progress</option>`;
    content += `<option value="Goal Achieved">Goal Achieved</option>`;

    content += "</select>";
    return super.render(`
      <div id="${this.component.key}" class="progressTowardsGoalsComp">
        <table class="table table-bordered goals-table w-100">
          <tbody>
            <tr>
              <td class="bg-light-grey" width="30%">Progress Towards Goals</td>
              <td style="padding: 0px;">
                ${content}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`${this.component.key}`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`${this.component.key}`][0], 'change', () => this.updateValue())

    return super.attach(element);
  }

  getValue() {
    var value = '';
    value = this.refs[`${this.component.key}`][0].value;
    $(`select#${this.component.key}`).attr("value", value);
    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    this.refs[`${this.component.key}`][0].value = value;
    $(`select#${this.component.key}`).attr("value", value);
  }

}
