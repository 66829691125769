import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "description",
        label: "Description",
        components: [
          {
            type: "textfield",
            input: true,
            label: "<p>This widget is primarily to be used in your treatment plan forms. The widget will display your chosen client’s Achieved Goals on your form. These achieved goals are established in the Client Goals section located within the Therapy tab.</p><p><em>Please note that we recommend that you place this widget within any layout with a width of 8 or more segments due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input"
          }
        ]
      },
      {
        key: "display",
        ignore: true
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
