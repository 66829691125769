import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Add Group",
            weight: 10,
            key: "groupName", // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textarea",
            input: true,
            label: "Description",
            weight: 11,
            key: "text", // This will be available as component.myCustomSetting
          },
  
          
          {
            type: "radio",
            label: "Color Theme",
            weight: 14,
            key: "colorTheme",
            defaultValue: 'bg-white',
            values: [
              {value: 'bg-white', label: 'White'},
              {value: 'bg-lightgray', label: 'Light Gray'}
            ]
          },
          {
            type: "checkbox",
            label: "Border",
            weight: 14,
            key: "textBorder",
            tooltip: "Explanatory Text will be displayed with a border.",
            value: "border border-dark"
          },
          {
            type: "checkbox",
            label: "Bold",
            weight: 14,
            key: "textBold",
            tooltip: "Explanatory Text will be displayed with a bold.",
            value: "text-bold"
          }
          ,
          {
            type: "checkbox",
            label: "Italic",
            weight: 14,
            key: "textItalic",
            tooltip: "Explanatory Text will be displayed with a italic.",
            value: "text-italic"
          },
          {
            type: "checkbox",
            label: "Underline",
            weight: 14,
            key: "textUnderline",
            tooltip: "Explanatory Text will be displayed with a underline.",
            value: "text-underline"
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
