import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Title (Optional)",
            weight: 1,
            key: "title",
            placeholder: "Title"
          },
          {
            type: "select",
            label: "Title Alignment",
            weight: 2,
            key: "titleAlign",
            data: {
              values: [
                {value: 'text-left', label: 'Left'},
                {value: 'text-center', label: 'Center'},
                {value: 'text-right', label: 'Right'}
              ]
            }
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "select",
            label: "Number of Rows",
            weight: 3,
            key: "numberOfRows",
            data: {
              values: [
                {value: 1, label: 1},
                {value: 2, label: 2},
                {value: 3, label: 3},
                {value: 4, label: 4},
                {value: 5, label: 5},
                {value: 6, label: 6},
                {value: 7, label: 7},
                {value: 8, label: 8},
                {value: 9, label: 9},
                {value: 10, label: 10}
              ]
            }
          },
          {
            label: 'Edit Column Headings and Width',
            weight: 4,
            key: 'tableColumns',
            type: 'datagrid',
            input: true,
            validate: {
              minLength: 1,
              maxLength: 10
            },
            components: [
              {
                label: 'Column #',
                key: 'srNo',
                type: 'textfield',
                input: true
              },
              {
                label: 'Column Heading',
                key: 'columnHeading',
                type: 'textfield',
                input: true
              },
              {
                label: 'Placeholder',
                key: 'columnPlaceholder',
                type: 'textfield',
                input: true
              },
              {
                label: 'Width',
                key: 'columnWidth',
                type: 'number',
                placeholder: '%',
                input: true
              }
            ]
          },


          {
            type: "checkbox",
            label: "Required to save note",
            weight: 5,
            key: "requiredSaveNote",
            tooltip: "Selecting this box requires the Clinician to enter text minimally within one grid in the table before the note can be saved."
          },
          {
            type: "checkbox",
            label: "Add Tooltip",
            weight: 6,
            key: "addTooltip"
          },
          {
            type: "textfield",
            label: "",
            weight: 7,
            key: "tooltipDescription",
            placeholder: "Add a descriptor to this component of the form",
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.addTooltip"
                  },
                  true
                ]
              }
            }
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
