import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./Table.settingsForm";

export default class Table extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Table",
      icon: "table",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: Table.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "tableCustomComp",
      title: "",
      titleAlign: "text-left",
      numberOfRows: 3,
      tableColumns: [],
      requiredSaveNote: false,
      addTooltip: false,
      tooltipDescription: ""
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Table");
    $(".datagrid-table th").each(function(index) {
      if(index==0){
        $(this).html("Order");
      }       
      if(index==4){
        $(this).html("Remove");
      }
    });
    // For changing column sr no index start
    $.each($('input').filter('[name ^="data[tableColumns]"][name $="[srNo]"]').get(), function( index, input ) {
      $(this).val(index + 1);
      $(this).attr("readonly", true);
    });
    // For changing column sr no index end

    let table_title = "";
    table_title += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required' : ''}">`;
    table_title += `${this.component.title || ''}`;
    table_title += `</span>`;
    if (this.component.addTooltip && this.component.tooltipDescription){
      table_title += ` <i class="fa fa-question-circle text-muted" ref="tooltip" data-title="${this.component.tooltipDescription}"></i>`;
    }

    let tableClass = 'table table-bordered ';
    ['striped', 'bordered', 'hover', 'condensed'].forEach((prop) => {
      if (this.component[prop]) {
        tableClass += `table-${prop} `;
      }
    });

    let table_head = '<thead><tr>';

    let totalColumnWidth = 0 ;
    let inputFocus = '' ;
    let inputFocusValue = '' ;
    let col_index = 0;
    for (let j = 0; j < this.component.tableColumns.length; j++) {
      $(`[name="data[tableColumns][${j}][columnWidth]"]`).attr("value",this.component.tableColumns[j].columnWidth);
      if (this.component.tableColumns[j].columnWidth > 0){
        totalColumnWidth += parseFloat($(`[name="data[tableColumns][${j}][columnWidth]"]`).val()) || 0;
      }
      let columnWidth = this.component.tableColumns[j].columnWidth
      if ($(`[name="data[tableColumns][${j}][columnWidth]"]`).is(":focus")) {
        inputFocus = `[name="data[tableColumns][${j}][columnWidth]"]`;
        col_index = j
        inputFocusValue= $(inputFocus).val();
      }
      if (totalColumnWidth < 100){
        table_head += `<th width='${columnWidth}%'>${this.component.tableColumns[j].columnHeading}</th>`;
      }
      else{
        table_head += `<th>${this.component.tableColumns[j].columnHeading}</th>`;
      }
    }
     for (let j = 0; j < this.component.tableColumns.length; j++) {
      $(`[name="data[tableColumns][${j}][columnWidth]"]`).removeClass('error');
     }
    if (totalColumnWidth > 100){
      // this.component.tableColumns[col_index].columnWidth = 0;
      $(inputFocus).attr("value",'');
      $(inputFocus).val('');
      $(inputFocus).addClass('error');
    }

    table_head += '</tr></thead>';

    let content = '';

    for (let i = 0; i < this.component.numberOfRows; i++) {
      let row = '<tr>';
      for (let j = 0; j < this.component.tableColumns.length; j++) {
        let cell = '<td>';

        cell += this.renderTemplate('input', {
          input: {
            type: 'input',
            ref: `${this.component.key}-${i}`,
            attr: {
              id: `${this.component.key}-${i}-${j}`,
              class: 'line-input w-100',
              type: 'text',
              placeholder: `${this.component.tableColumns[j].columnPlaceholder}`
            }
          }
        });

        cell += '</td>';
        row += cell;
      }
      row += '</tr>';
      content += row;
    }

    return super.render(`
      <div id="${this.component.key}" class="${this.component.requiredSaveNote ? 'required-component-table' : ''}">
        <div class="${this.component.titleAlign}">
          ${table_title}
        </div>
        <div class="table-responsive">
          <table class="${tableClass}">
            ${table_head}
            <tbody>
              ${content}
            </tbody>
          </table>
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    for (let i = 0; i < this.component.numberOfRows; i++) {
      refs[`${this.component.key}-${i}`] = '';
    }

    this.loadRefs(element, refs);

    this.checks = [];
    for (let i = 0; i < this.component.numberOfRows; i++) {
      this.checks[i] = Array.prototype.slice.call(this.refs[`${this.component.key}-${i}`], 0);

      this.checks[i].forEach(input => {
        this.addEventListener(input, 'keyup', () => this.updateValue())
      });
    }
    
    return super.attach(element);
  }

  getValue() {
    var value = [];
    for (var rowIndex in this.checks) {
      var row = this.checks[rowIndex];
      value[rowIndex] = [];
      for (var colIndex in row) {
        var col = row[colIndex];
        value[rowIndex][colIndex] = col.value;
        $('#'+col.id).attr("value",value[rowIndex][colIndex]); // This is for export PDF
      }
    }
    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    for (var rowIndex in this.checks) {
      var row = this.checks[rowIndex];
      if (!value[rowIndex]) {
        break;
      }
      for (var colIndex in row) {
        var col = row[colIndex];
        col.value = value[rowIndex][colIndex];
        $('#'+col.id).attr("value",value[rowIndex][colIndex]); // This is for export PDF
      }
    }
  }

}
