import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "description",
        label: "Description",
        components: [
          {
            type: "textfield",
            input: true,
            label: "<p>This widget pertains to the progress towards your client's active goals. The User will choose between the following Regressed, No Progress, Minor Progress, Moderate Progress, Significant Progress and Goal Achieved <br/> <br/><em>Please note that we recommend that place this widgets within any layout with a width of 4 or more segments due to space and formatting constraints</em> </p>",
            weight: 1,
            customClass: "hide-input"
          }
        ]
      },
      {
        key: "display",
        ignore: true
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
