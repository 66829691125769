import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "description",
        label: "Description",
        components: [
          {
            type: "textfield",
            input: true,
            label: "<p>This widget allows you to add a signature box to your form.  Choose the signature(s) that will correspond to the User types that will engage with the form.  Note that specific signature boxes activate specific icons in the Software.  Provider, Therapist, Nursing, Clinician, and Other activate the 'Sign' icon. Client, Family, Client/Family activates the 'Client Sign' and 'Family Sign' icons (only available while in Client Mode).</p><p><em>Please note that we recommend that you do not place the Signature widget within any layout where the sections are divided due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input"
          },
          {
            type: "radio",
            label: "Choose which signature to include",
            weight: 2,
            key: "signatureType",
            values: [
              {value: 'Provider', label: 'Provider'},
              {value: 'Therapist', label: 'Therapist'},
              {value: 'Clinician', label: 'Clinician'},
              {value: 'Nursing', label: 'Nursing'},
              {value: 'Client', label: 'Client'},
              {value: 'Family', label: 'Family'},
              {value: 'Client/Family', label: 'Client/Family'},
              {value: 'Other', label: 'Other'}
            ]
          },
          {
            type: "textfield",
            label: "",
            weight: 3,
            key: "signatureTypeOther",
            placeholder: "Enter Signature Type",
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.signatureType"
                  },
                  'Other'
                ]
              }
            }
          }
        ]
      },
      {
        key: "display",
        ignore: true
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
