import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          { key: "description", ignore: true },
          { key: "customClass", ignore: true },
          { key: "autofocus", ignore: true },
          {
            type: "textfield",
            input: true,
            label:
              "<p>This widget is primarily to be used Goals in your treatment plan forms. The widget will display your chosen client’s goals on your form. These goals are established in the Client Goals section located within the Therapy tab.</p><p><em>Please note that we recommend that you place this widget within any layout with a width of 8 or more segments due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input",
          },
          {
            type: "select",
            label: "Goal Type",
            weight: 2,
            key: "goalsRadioButton",
            customClass: "goals-custom-height",
            data: {
              values: [
                { value: "1", label: "Active Goals" },
                { value: "2", label: "Achieved Goals" },
                { value: "3", label: "Revised Goals" },
                { value: "4", label: "Discontinued Goals" },
                { value: "5", label: "Deferred Goals" },
                { value: "6", label: "Progress Towards Goals" },
              ],
            },
            validate: { required: true },
            errors: { required: "Please pre-select first from list" },
            defaultValue: 1,
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "api",
        ignore: true,
      },
      {
        key: "conditional",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        key: "layout",
        ignore: true,
      },
    ],
    ...extend
  );
};
