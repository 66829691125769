import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./CheckBoxes.settingsForm";

export default class CheckBoxes extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Check Boxes",
      icon: "check-square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: CheckBoxes.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "checkBoxesCustomComp",
      checkBoxLabel: "",
      checkBoxLayout: "form-check",
      checkBoxesColumns: [],
      requiredSaveNote: false,
      addTooltip: false,
      tooltipDescription: ""
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Check Boxes");
    $(".datagrid-table th").each(function(index) {
      if(index==0){
        $(this).html("Order");
      }       
      if(index==2){
        $(this).html("Remove");
      }
    });
    let checkbox_label = "";
    checkbox_label += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required' : ''}">`;
    checkbox_label += `${this.component.checkBoxLabel || ''}`;
    checkbox_label += `</span>`;
    if (this.component.addTooltip && this.component.tooltipDescription){
      checkbox_label += ` <i class="fa fa-question-circle text-muted" ref="tooltip" data-title="${this.component.tooltipDescription}"></i>`;
    }

    let content = `<div class="form-group">`;
    let other = '';

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      let cell = `<div class="${this.component.checkBoxLayout}">`;
      cell += `<label for="${this.component.key}-${i}" class="form-check-label">`;
      cell += this.renderTemplate('input', {
        input: {
          type: 'input',
          ref: `${this.component.key}-${i}`,
          attr: {
            id: `${this.component.key}-${i}`,
            class: `form-check-input ${this.component.requiredSaveNote ? 'required' : ''}`,
            type: 'checkbox',
          }
        }
      });
      cell += `<span>${this.component.checkBoxesColumns[i].checkBoxText}</span>`;
      cell += `</label>`;
      cell += `</div>`;
      content += cell;
    }

    if (this.component.includeOther){
      other += `<div class="${this.component.checkBoxLayout}">`;
      other += `<label for="${this.component.key}-checkbox-other" class="form-check-label">`;
      other += this.renderTemplate('input', {
        input: {
          type: 'input',
          ref: `${this.component.key}-checkbox-other`,
          attr: {
            id: `${this.component.key}-checkbox-other`,
            class: `form-check-input checkBoxesCustomComp-checkbox-other ${this.component.requiredSaveNote ? 'required' : ''}`,
            type: 'checkbox',
          }
        }
      });
      other += `<span>Other</span>`;
      other += `</label>`;
      other += this.renderTemplate('input', {
        input: {
          type: 'input',
          ref: `${this.component.key}-other`,
          attr: {
            id: `${this.component.key}-other`,
            class: 'line-input form-check-input-other',
            type: 'text',
          }
        }
      });
      other += `</div>`;
      content += other;
    }

    content += `</div>`;

    return super.render(`
      <div id="${this.component.key}" class="${this.component.requiredSaveNote ? 'required-component-checkboxes' : ''}">
        <div>
          ${checkbox_label}
        </div>
        <div class="form-radio radio">
          ${content}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      refs[`${this.component.key}-${i}`] = '';
    }
    refs[`${this.component.key}-checkbox-other`] = '';
    refs[`${this.component.key}-other`] = '';

    this.loadRefs(element, refs);

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      this.addEventListener(this.refs[`${this.component.key}-${i}`][0], 'change', () => this.updateValue())
    }
    this.addEventListener(this.refs[`${this.component.key}-checkbox-other`][0], 'change', () => this.updateValue())
    this.addEventListener(this.refs[`${this.component.key}-other`][0], 'keyup', () => this.updateValue())

    return super.attach(element);
  }

  getValue() {
    var value = [];
    value[0] = [];
    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      value[0][i] = this.refs[`${this.component.key}-${i}`][0].checked;
      if(value[0][i]){
        $(`input#${this.component.key}-${i}`).attr("checked", "checked");
      }
      else{
        $(`input#${this.component.key}-${i}`).removeAttr("checked");
      }
    }
    if(this.refs[`${this.component.key}-checkbox-other`][0]){
      value[1] = this.refs[`${this.component.key}-checkbox-other`][0].checked;
      value[2] = this.refs[`${this.component.key}-other`][0].value;
    }
    if (value[1]){
      $(`input#${this.component.key}-checkbox-other`).attr("checked", "checked");
    }
    else{
      $(`input#${this.component.key}-checkbox-other`).removeAttr("checked");
    }
    if (value[2]){
      $(`input#${this.component.key}-other`).attr("value", value[2]);
    }
    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      let checked = value[0][i] ? 1 : 0;
      this.refs[`${this.component.key}-${i}`][0].value = checked;
      this.refs[`${this.component.key}-${i}`][0].checked = checked;
      if(checked){
        $(`input#${this.component.key}-${i}`).attr("checked", "checked");
      }
      else{
        $(`input#${this.component.key}-${i}`).removeAttr("checked");
      }
    }
    if (value[1]){
      this.refs[`${this.component.key}-checkbox-other`][0].value = value[1];
      this.refs[`${this.component.key}-checkbox-other`][0].checked = value[1];
    }
    if (value[1]){
      $(`input#${this.component.key}-checkbox-other`).attr("checked", "checked");
    }
    else{
      $(`input#${this.component.key}-checkbox-other`).removeAttr("checked");
    }
    if (value[2]){
      this.refs[`${this.component.key}-other`][0].value = value[2];
      $(`input#${this.component.key}-other`).attr("value", value[2]);
    }
  }

}
