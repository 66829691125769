import React from "react";
import PropTypes from "prop-types"
import { FormBuilder, Components, Form } from "react-formio";
import components from "./Custom";

Components.setComponents(components);

class RenderFormBuilder extends React.Component {

  submitFormData (schema) {    
  }

  render () {
    var form_json_data = JSON.parse(this.props.form_builder.content);
    var form_builder_data = {};
    if (this.props.form_builder_data){
      form_builder_data = this.props.form_builder_data.value
    }

    return (
      <div>
        <Form
        	form={form_json_data}
        	submission={{data: form_builder_data}}
          onChange={(schema) => this.submitFormData(schema)}
        	// onSubmit={console.log}
      	/>
      </div>
    );
  }
}

RenderFormBuilder.propTypes = {
};

export default RenderFormBuilder
