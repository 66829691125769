import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },

         {
            type: "textfield",
            input: true,
            label: "<p>This widget allows you to add different sections of the client’s demographic profile into the notes.  Please note that the information is directly pulled form the client’s profile page.  Also, if you make any changes in the demographic fields from within this widget, those changes will also be reflected in the client’s profile page.</p><p><em>Please note that we recommend that you do not place the Demographic widget within any layout where the sections are divided due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input"
          },
          {
            type: "checkbox",
            label: "Main Demographic Info +",
            weight: 6,
            key: "demographicInfo",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "First and Last Name",
            weight: 7,
            key: "firstandlastName",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Preferred First and Last Name",
            weight: 7,
            key: "preferredFirstandlastName",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "DOB",
            weight: 7,
            key: "dob",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Social Security #",
            weight: 7,
            key: "socialSecurity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Cell Phone",
            weight: 7,
            key: "cellPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Work Phone",
            weight: 7,
            key: "workPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Home Phone",
            weight: 7,
            key: "homePhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.demographicInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Guardian Info +",
            weight: 8,
            key: "guardianInfo",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "First and Last Name",
            weight: 8,
            key: "guardianInfofirstandlastName",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Date Of Birth",
            weight: 8,
            key: "guardianInfodob",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Email",
            weight: 8,
            key: "guardianInfoEmail",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address 1",
            weight: 8,
            key: "guardianInfoaddress1",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address 2",
            customClass: 'inner-field',
            defaultValue: true,
            weight: 8,
            key: "guardianInfoaddress2",
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "City",
            weight: 8,
            key: "guardianInfocity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "State",
            weight: 8,
            key: "guardianInfostate",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Zip Code",
            weight: 8,
            key: "guardianInfozipcode",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Cell Phone",
            weight: 8,
            key: "guardianInfocellPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Work Phone",
            weight: 8,
            key: "guardianInfoworkPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Home Phone",
            weight: 8,
            key: "guardianInfohomePhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guardianInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Client Address +",
            weight: 9,
            key: "clientAddress",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "Street Address 1",
            weight: 9,
            key: "address1",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.clientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address 2",
            customClass: 'inner-field',
            defaultValue: true,
            weight: 10,
            key: "address2",
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.clientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "City",
            weight: 10,
            key: "city",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.clientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "State",
            weight: 10,
            key: "state",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.clientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Zip Code",
            weight: 10,
            key: "zipcode",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.clientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Personal Info +",
            weight: 10,
            key: "personalInfo",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "Gender",
            weight: 10,
            key: "gender",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.personalInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Gender Identity",
            weight: 10,
            key: "genderIdentity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.personalInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Sexual Orientation",
            weight: 10,
            key: "sexualOrientation",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.personalInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Race",
            weight: 10,
            key: "race",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.personalInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Ethnicity",
            weight: 10,
            key: "ethnicity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.personalInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Language",
            weight: 10,
            key: "language",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.personalInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Primary Contact Info +",
            weight: 11,
            key: "primaryContactInfo",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "First and Last Name",
            weight:11,
            key: "primaryContactFirstandlastName",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Relationship",
            weight:11,
            key: "primaryContactRelationship",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "DOB",
            weight:11,
            key: "primaryContactdob",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address",
            weight:11,
            key: "primaryContactAddress",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "City",
            weight:11,
            key: "primaryContactCity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "State",
            weight:11,
            key: "primaryContactState",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Zip Code",
            weight:11,
            key: "primaryContactzipcode",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Phone",
            weight:11,
            key: "primaryContactPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Email",
            weight:11,
            key: "primaryContactEmail",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.primaryContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Additional Client Address +",
            weight: 12,
            key: "additionalClientAddress",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "Street Address 1",
            weight:12,
            key: "additionalClientAddress1",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.additionalClientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address 2",
            weight:12,
            key: "additionalClientAddress2",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.additionalClientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "City",
            weight:12,
            key: "additionalClientAddressCity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.additionalClientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "State",
            weight:12,
            key: "additionalClientAddressState",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.additionalClientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Zip Code",
            weight:12,
            key: "additionalClientAddressZipcode",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.additionalClientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Phone",
            weight:12,
            key: "additionalClientAddressPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.additionalClientAddress"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Emergency (Secondary) Contact Info +",
            weight: 13,
            key: "emergencyContactInfo",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "First and Last Name",
            weight:13,
            key: "emergencyContactInfoFirstandlastName",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Relationship",
            weight:13,
            key: "emergencyContactInfoRelationship",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "DOB",
            weight:13,
            key: "emergencyContactInfodob",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address",
            weight:13,
            key: "emergencyContactInfoAddress",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "City",
            weight:13,
            key: "emergencyContactInfoCity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "State",
            weight:13,
            key: "emergencyContactInfoState",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Zip Code",
            weight:13,
            key: "emergencyContactInfozipcode",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Phone",
            weight:13,
            key: "emergencyContactInfoPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Email",
            weight:13,
            key: "emergencyContactInfoEmail",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.emergencyContactInfo"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Guarantor +",
            weight: 14,
            key: "guarantor",
            customClass: 'bold',
          },
          {
            type: "checkbox",
            label: "First and Last Name",
            weight:14,
            key: "guarantorFirstandlastName",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Relationship",
            weight:14,
            key: "guarantorRelationship",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "DOB",
            weight:14,
            key: "guarantordob",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Street Address",
            weight:14,
            key: "guarantorAddress",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "City",
            weight:14,
            key: "guarantorCity",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "State",
            weight:14,
            key: "guarantorState",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Zip Code",
            weight:14,
            key: "guarantorzipcode",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Phone",
            weight:14,
            key: "guarantorPhone",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          },
          {
            type: "checkbox",
            label: "Email",
            weight:14,
            key: "guarantorEmail",
            customClass: 'inner-field',
            defaultValue: true,
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.guarantor"
                  },
                  true
                ]
              }
            }
          }

        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
