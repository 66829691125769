import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          { key: "description", ignore: true },
          { key: "customClass", ignore: true },
          { key: "autofocus", ignore: true },
          {
            type: "textfield",
            label:
              "<p>This widget allows you to select which Symptoms of our psych review of systems you would like to display.  The selections from each time you use the widget for a particular client will carry over to any other subsequent time it’s used.</p><p>This widget can also be used in client forms for them to use.</p><p><em>Please note that we recommend that you do not place this widget within any layout where the sections are divided due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input",
          },
          {
            type: "checkbox",
            label: "Depressive Symptoms",
            key: "depressiveSymptoms",
            weight: 2,
          },
          {
            type: "checkbox",
            label: "Manic or Hypomanic Symptoms",
            key: "manicOrHypomanicSymptoms",
            weight: 3,
          },
          {
            type: "checkbox",
            label: "Anxiety Symptoms",
            key: "anxietySymptoms",
            weight: 4,
          },
          {
            type: "checkbox",
            label: "Panic Attack Symptoms",
            key: "panicAttackSymptoms",
            weight: 5,
          },
          {
            type: "checkbox",
            label: "PTSD Symptoms",
            key: "pTSDSymptoms",
            weight: 6,
          },
          {
            type: "checkbox",
            label: "Psychotic Symptoms",
            key: "psychoticSymptoms",
            weight: 7,
          },

          {
            type: "checkbox",
            label: "Personality Disorder Symptoms",
            key: "personalityDisorderSymptoms",
            weight: 8,
          },

          {
            type: "checkbox",
            label: "Neurocognitive Symptoms",
            key: "neurocognitiveSymptoms",
            weight: 9,
          },
          {
            type: "checkbox",
            label: "Disruptive Mood Dysregulation Symptoms (child/adol)",
            key: "disruptiveMoodDysregulationSymptoms",
            weight: 10,
          },
          {
            type: "checkbox",
            label: "ADHD Symptoms (child/adol)",
            key: "aDHDSymptoms",
            weight: 11,
          },

          {
            type: "checkbox",
            label: "Anxiety Symptoms (child/adol)",
            key: "anxietyAdolSymptoms",
            weight: 12,
          },
          {
            type: "checkbox",
            label: "Other Behavioral Symptoms (child/adol)",
            key: "otherBehavioralSymptoms",
            weight: 13,
          },

          {
            type: "checkbox",
            label: "Autism Symptoms (child/adol)",
            key: "autismSymptoms",
            weight: 14,
          },
        ],
      },
      {
        key: "data",
        ignore: true,
      },
      {
        key: "validation",
        ignore: true,
      },
      {
        key: "api",
        ignore: true,
      },
      {
        key: "conditional",
        ignore: true,
      },
      {
        key: "logic",
        ignore: true,
      },
      {
        key: "layout",
        ignore: true,
      },
    ],
    ...extend
  );
};
