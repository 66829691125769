import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./TextField.settingsForm";

export default class TextField extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Text Field Single Line",
      icon: "minus",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: TextField.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "textFieldCustomComp",
      textFieldLabel: "",
      textFieldPlaceholder: "",
      colorTheme: "bg-white",
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Text Field Single Line");

    let textfield_label = "";
    textfield_label += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required' : ''}">`;
    textfield_label += `${this.component.textFieldLabel || ''}`;
    textfield_label += `</span>`;
    if (this.component.addTooltip && this.component.tooltipDescription){
      textfield_label += ` <i class="fa fa-question-circle text-muted" ref="tooltip" data-title="${this.component.tooltipDescription}"></i>`;
    }

    var textFieldSingleLine = ''
    var attr_json = {
                      id: `${this.component.key}-textFieldSingleLine`,
                      class: `line-input w-100 ${this.component.requiredSaveNote ? 'required' : ''}`,
                      type: 'text',
                      placeholder: `${this.component.textFieldPlaceholder || ''}`
                    }
    if (this.component.requiredSaveNote){
      attr_json.required = 'required'
    }
    var textFieldSingleLine = this.renderTemplate('input', {

      input: {
        type: 'input',
        ref: `${this.component.key}-textFieldSingleLine`,
        attr: attr_json
      }
    });

    return super.render(`
      <div id="${this.component.key}" class="${this.component.colorTheme}">
        <div>
          ${textfield_label}
        </div>
        <div class="form-group">
          ${textFieldSingleLine}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`${this.component.key}-textFieldSingleLine`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`${this.component.key}-textFieldSingleLine`][0], 'keyup', () => this.updateValue())
    
    return super.attach(element);
  }

  getValue() {
    var value = '';
    value = this.refs[`${this.component.key}-textFieldSingleLine`][0].value;
    $(`input#${this.component.key}-textFieldSingleLine`).attr("value", value);
    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    this.refs[`${this.component.key}-textFieldSingleLine`][0].value = value;
    $(`input#${this.component.key}-textFieldSingleLine`).attr("value", value);
  }

}
