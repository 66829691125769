import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            type: "radio",
            label: "Divider Thickness",
            weight: 1,
            key: "dividerThickness",
            tooltip: "Thickness of the divider ",
            values: [
              {value: '18', label: 'Extra Large'},
              {value: '16', label: 'Large'},
              {value: '12', label: 'Medium'},
              {value: '8', label: 'Small'},
              {value: '6', label: 'Extra Small'}
            ]
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
