import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Label (Optional)",
            weight: 1,
            key: "phoneLabel",
            placeholder: "Phone Number"
          },
          {
            type: "select",
            label: "Label Alignment",
            weight: 2,
            key: "labelAlign",
            data: {
              values: [
                {value: 'text-left', label: 'Left'},
                {value: 'text-center', label: 'Center'},
                {value: 'text-right', label: 'Right'}
              ]
            }
          },
          {
            type: "radio",
            label: "Color Theme",
            weight: 14,
            key: "colorTheme",
            values: [
              {value: 'bg-white', label: 'White'},
              {value: 'bg-lightgray', label: 'Light Gray'}
            ]
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
