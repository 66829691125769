import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./BloodPressure.settingsForm";

const BloodPressureComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }
};

export default class BloodPressure extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Blood Pressure",
      icon: "heart",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: BloodPressure.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "bloodPressureComp",
      label: "Blood Pressure"
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Blood Pressure");

    var bloodPressureLow = ''
    var bloodPressureHigh = ''
    
    var bloodPressureLow = this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `bloodPressureLow`,
        attr: {
          id: `bloodPressureLow`,
          class: 'form-control',
          type: 'text',
          placeholder: "Blood Pressure Low"
        }
      }
    });
    var bloodPressureHigh = this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `bloodPressureHigh`,
        attr: {
          id: `bloodPressureHigh`,
          class: 'form-control',
          type: 'text',
          placeholder: "Blood Pressure High"
        }
      }
    });

    return super.render(`
      <div id="${this.component.key}">
        <label class="col-form-label">${this.component.label}</label>
        <div class="form-group">
          <div class="form-group">
            ${bloodPressureLow}
          </div>
          <div class="form-group">
            ${bloodPressureHigh}
          </div>
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    refs[`bloodPressureLow`] = '';
    refs[`bloodPressureHigh`] = '';

    this.loadRefs(element, refs);

    this.addEventListener(this.refs[`bloodPressureLow`][0], 'keyup', () => this.updateValue())
    this.addEventListener(this.refs[`bloodPressureHigh`][0], 'keyup', () => this.updateValue())
    
    return super.attach(element);
  }

  getValue() {
    var value = [];
    value[0] = this.refs[`bloodPressureLow`][0].value;
    value[1] = this.refs[`bloodPressureHigh`][0].value;
    $("input#bloodPressureLow").attr("value",value[0]);
    $("input#bloodPressureHigh").attr("value",value[1]);
    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    this.refs[`bloodPressureLow`][0].value = value[0];
    this.refs[`bloodPressureHigh`][0].value = value[1];
    $("input#bloodPressureLow").attr("value",value[0]);
    $("input#bloodPressureHigh").attr("value",value[1]);
  }

}
