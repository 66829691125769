import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          {
            // You can ignore existing fields.
            key: "label",
            ignore: true
          },
          {
            // You can ignore existing fields.
            key: "placeholder",
            ignore: true
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Text",
            weight: 12,
            key: "text" // This will be available as component.myCustomSetting
          },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "select",
            // id: "test_select",
            // input: true,
            label: "Select Text Size",
            weight: 13,
            key: "selectTextSize", // This will be available as component.myCustomSetting
            // placeholder: "Select one",
            data: {
              values: [
                {value: 'h1', label: 'Title'},
                {value: 'h3', label: 'SubTitle'},
                {value: 'p', label: 'Normal Text'}
              ]
            }
          }
        ]
      },
      {
        key: "data",
        components: []
      },
      {
        key: "validation",
        components: []
      },
      {
        key: "api",
        components: []
      },
      {
        key: "conditional",
        components: []
      },
      {
        key: "logic",
        components: []
      }
    ],
    ...extend
  );
};
