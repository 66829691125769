import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Label (Optional)",
            weight: 1,
            key: "textAreaLabel"
          },
          {
            type: "number",
            input: true,
            label: "Field Size (select # of rows)",
            weight: 2,
            key: "textAreaFieldSize",
            validate: {
              required: true,
              min: 1
            }
          },
          {
            type: "textfield",
            input: true,
            label: "Placeholder Text (Optional)",
            weight: 3,
            key: "textAreaPlaceholder"
          },
          {
            type: "radio",
            label: "Color Theme",
            weight: 14,
            key: "colorTheme",
            values: [
              {value: 'bg-white', label: 'White'},
              {value: 'bg-lightgray', label: 'Light Gray'}
            ]
          },
          {
            type: "checkbox",
            label: "Required to save note",
            weight: 5,
            key: "requiredSaveNote",
            tooltip: "Selecting this box requires the Clinician to enter text into the text field before the note can be saved."
          },
          {
            type: "checkbox",
            label: "Add Tooltip",
            weight: 6,
            key: "addTooltip",
          },
          {
            type: "textfield",
            label: "",
            weight: 7,
            key: "tooltipDescription",
            placeholder: "Add a descriptor to this component of the form",
            conditional: {
              "json": {
                "===": [
                  {
                    "var": "data.addTooltip"
                  },
                  true
                ]
              }
            }
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
