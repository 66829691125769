import React, { Component } from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "react-formio";
import settingsForm from "./PsychROS.settingsForm";

/**
 * An example React component
 *
 * Replace this with your custom react component. It needs to have two things.
 * 1. The value should be stored is state as "value"
 * 2. When the value changes, call props.onChange(null, newValue);
 *
 * This component is very simple. When clicked, it will set its value to "Changed".
 */
const PsychROSComp = class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component: this.props.component,
      value: this.props.value,
      psychROS: {
        depressiveSymptoms: {
          depressiveSymptomsCheck:
            this.props.component.depressiveSymptoms || false,
          depressedMood: false,
          sleepDisturbance: false,
          lossOfInterestInPleasurableActivities: false,
          feelingOfGuiltOrWorthlessness: false,
          lowEnergyOrFatigue: false,
          problemsWithConcentration: false,
          weightOrAppetiteChangesLossOrGain: false,
          appetiteChanges: false,
          psychomotorRetardationActivation: false,
          suicidalIdeation: false,
          elaborate: "",
        },
        psychoticSymptoms: {
          psychoticSymptomsCheck:
            this.props.component.psychoticSymptoms || false,
          hallucinations: false,
          delusions: false,
          disorganizedSpeechOrThoughts: false,
          disorganizedOrCatatonicBehavior: false,
          lackOfEmotionsOrFacialExpression: false,
          lackOfInterestInActivitiesOrSocialEngagement: false,
          poorHygeine: false,
          lackOfEnergy: false,
          elaborate: "",
        },
        disruptiveMoodDysregulationSymptoms: {
          disruptiveMoodDysregulationSymptomsCheck:
            this.props.component.disruptiveMoodDysregulationSymptoms || false,
          persistentIrritableOrAngryMood: false,
          inappropriateTemperOutbursts: false,
          elaborate: "",
        },
        manicOrHypomanicSymptoms: {
          manicOrHypomanicSymptomsCheck:
            this.props.component.manicOrHypomanicSymptoms || false,
          euphoricoodOrExcessiveIrritabilityWithLittleProvocation: false,
          ideasOfGrandeurOrInflatedSelfesteen: false,
          decreasedNeedForSleepOrFeelingRestedAfterCoupleHrsOfSleep: false,
          pressuredSpeechOrHyperverbal: false,
          racingThoughtsOrFlightOfIdeas: false,
          easilyDistractedOnUnimportantOrIrrelevantActivities: false,
          increasedGoalDirectedActivitiesOrPsychomotorAgitation: false,
          increasedActivityWithHigherPotentialForHarmfulConsequences: false,
          elaborate: "",
        },
        personalityDisorderSymptoms: {
          personalityDisorderSymptomsCheck:
            this.props.component.personalityDisorderSymptoms || false,
          instabilityInEmotionsRelationshipsOrSelfImage: false,
          needToBeTheCenterOfAttention: false,
          feelingNoRemorseWithOnesBehaviorThatViolateOthers: false,
          obsessionWithSuccessPowerBeautyOrLove: false,
          elaborate: "",
        },
        neurocognitiveSymptoms: {
          neurocognitiveSymptomsCheck:
            this.props.component.neurocognitiveSymptoms || false,
          cognitiveDeclineInLearningAndMemory: false,
          languageComprehensionAndProductionIssues: false,
          executiveFunctioningDecline: false,
          complexAttentionDeficiency: false,
          perceptualMotorDecline: false,
          socialCognitionDecline: false,
          elaborate: "",
        },
        aDHDSymptoms: {
          aDHDSymptomsCheck: this.props.component.aDHDSymptoms || false,
          persistentLackOfAttentionToDetail: false,
          troubleStayingFocused: false,
          difficultyFollowingInstructions: false,
          easyDistractibility: false,
          forgetfulness: false,
          oftenMisplacingPossessions: false,
          difficultySustainingMentalEffort: false,
          fidgetiness: false,
          restlessness: false,
          oftenTalksExcessively: false,
          blurtsOutAnswers: false,
          interruptsOthers: false,
          hasTroubleWaitingtheirTurn: false,
          symptomsPresentBeforeAge12: false,
          elaborate: "",
        },
        anxietySymptoms: {
          anxietySymptomsCheck: this.props.component.anxietySymptoms || false,
          childAdolCheck:
            this.props.component.anxietySymptomsChildAdol || false,
          excessiveAnxietiesAndWorries: false,
          restlessness: false,
          difficultyConcentrating: false,
          irritability: false,
          muscleTension: false,
          disruptedSleep: false,
          fearOfSocialInteractions: false,
          obsessions: false,
          compulsions: false,
          ruminations: false,
          fearOfBeingJudgedInSocialSituations: false,
          fearOfOpenSpaces: false,
          fearOfSpecificthings: false,
          panicAttacks: false,
          fearAndDistress: false,
          elaborate: "",
        },
        anxietyAdolSymptoms: {
          childAdolCheck:
            this.props.component.anxietyAdolSymptoms || false,
          excessiveAnxietiesAndWorries: false,
          restlessness: false,
          difficultyConcentrating: false,
          irritability: false,
          muscleTension: false,
          disruptedSleep: false,
          fearOfSocialInteractions: false,
          obsessions: false,
          compulsions: false,
          ruminations: false,
          fearOfBeingJudgedInSocialSituations: false,
          fearOfOpenSpaces: false,
          fearOfSpecificthings: false,
          panicAttacks: false,
          fearAndDistress: false,
          elaborate: "",
        },
        panicAttackSymptoms: {
          panicAttackSymptomsCheck:
            this.props.component.panicAttackSymptoms || false,
          palpitationsTachycardia: false,
          diaphoresis: false,
          tremulousness: false,
          shortnessOfBreath: false,
          chokingSensation: false,
          chestPainDiscomfort: false,
          nauseaAbdominalDistress: false,
          dizzyLightheadedFaintOrUnsteady: false,
          derealizationOrDepersonalization: false,
          fearOfLosingControl: false,
          impendingSenseOfDoomOrDying: false,
          paresthesias: false,
          chillsOrHeatSensations: false,
          avoidanceOfActivitiesForFearOfAnotherEpisode: false,
          elaborate: "",
        },
        otherBehavioralSymptoms: {
          otherBehavioralSymptomsCheck:
            this.props.component.otherBehavioralSymptoms || false,
          disobedientArgumentativeDefiant: false,
          questioningOrRefusalToFollowRules: false,
          blamingOthers: false,
          unkindVindictive: false,
          violatesBasicRightsOfOthersOrNormsRules: false,
          aggressionToPeopleAndAnimals: false,
          destructionOfProperty: false,
          deceitfulOrEngagingInTheftActivities: false,
          elaborate: "",
        },
        autismSymptoms: {
          autismSymptomsCheck: this.props.component.autismSymptoms || false,
          deficitsInSocialEmotionalReciprocity: false,
          deficitsInNonverbalCommunicationBehaviors: false,
          deficitsInDevelopingMaintainingAndUnderstandingRelationships: false,
          elaborate: "",
        },
        pTSDSymptoms: {
          pTSDSymptomsCheck: this.props.component.pTSDSymptoms || false,
          exposureToDeath: false,
          traumaIsOftenReExperienced: false,
          traumaAssociatedTriggers: false,
          associatedNegativeThoughts: false,
          associatedArousalAndReactivitySymptoms: false,
          elaborate: "",
        },
      },
    };
    this.props.onChange(this.state.psychROS);
  }

  handleCheckboxChange = (event) => {
    const { name, value } = event.target;
    const section = name.split(".")[0];
    const key = name.split(".")[1];

    this.setState(
      (prevState) => ({
        psychROS: {
          ...prevState.psychROS,
          [section]: {
            ...prevState.psychROS[section],
            [key]: !prevState.psychROS[section][key],
          },
        },
      }),
      () => {
        this.props.onChange(this.state.psychROS);
      }
    );
  };
  handleInputChange = (event) => {
    const { name, value } = event.target;
    const section = name.split(".")[0];
    const key = name.split(".")[1];

    this.setState(
      (prevState) => ({
        psychROS: {
          ...prevState.psychROS,
          [section]: {
            ...prevState.psychROS[section],
            [key]: value,
          },
        },
      }),
      () => {
        this.props.onChange(this.state.psychROS);
      }
    );
  };
  getValue() {
    return this.state.psychROS;
  }

  setValue(value) {
    if (value !== null) {
      this.setState({
        psychROS: {
          depressiveSymptoms: value.depressiveSymptoms,
          psychoticSymptoms: value.psychoticSymptoms,
          disruptiveMoodDysregulationSymptoms:
            value.disruptiveMoodDysregulationSymptoms,
          aDHDSymptoms: value.aDHDSymptoms,
          manicOrHypomanicSymptoms: value.manicOrHypomanicSymptoms,
          neurocognitiveSymptoms: value.neurocognitiveSymptoms,
          anxietySymptoms: value.anxietySymptoms,
          anxietyAdolSymptoms: value.anxietyAdolSymptoms,
          personalityDisorderSymptoms: value.personalityDisorderSymptoms,
          panicAttackSymptoms: value.panicAttackSymptoms,
          otherBehavioralSymptoms: value.otherBehavioralSymptoms,
          autismSymptoms: value.autismSymptoms,
          pTSDSymptoms: value.pTSDSymptoms,
        },
      });
    }
  }

  render() {
    $(".component-edit-container p.lead").html(
      "Psychiatric Review of Systems Widget"
    );

    return (
      <div id={this.state.component.key}>
        <div className="container-fluid" id="psychROS">
          <div className="row">
            <div className="col-12 text-center my-1">
              <label>
                <h2 className="bg-lightgray p-2 bold premium-color">
                  Psychiatric Review of Systems
                </h2>
              </label>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-md-4 ${
                this.state.psychROS.depressiveSymptoms
                  .depressiveSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="depressiveSymptoms"
            >
              <label className="bold premium-color">
                <b>Depressive Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    id="depressedMood"
                    name="depressiveSymptoms.depressedMood"
                    checked={
                      this.state.psychROS.depressiveSymptoms.depressedMood
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="depressedMood">
                    Depressed Mood
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.sleepDisturbance"
                    id="sleepDisturbance"
                    checked={
                      this.state.psychROS.depressiveSymptoms.sleepDisturbance
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="sleepDisturbance">
                    Sleep Disturbance
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.lossOfInterestInPleasurableActivities"
                    id="lossOfInterestInPleasurableActivities"
                    checked={
                      this.state.psychROS.depressiveSymptoms
                        .lossOfInterestInPleasurableActivities
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="lossOfInterestInPleasurableActivities"
                  >
                    Loss of interest in pleasurable activities
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.feelingOfGuiltOrWorthlessness"
                    id="feelingOfGuiltOrWorthlessness"
                    checked={
                      this.state.psychROS.depressiveSymptoms
                        .feelingOfGuiltOrWorthlessness
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="feelingOfGuiltOrWorthlessness"
                  >
                    Feeling of Guilt or worthlessness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.lowEnergyOrFatigue"
                    id="lowEnergyOrFatigue"
                    checked={
                      this.state.psychROS.depressiveSymptoms.lowEnergyOrFatigue
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="lowEnergyOrFatigue">
                    Low energy or fatigue
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.problemsWithConcentration"
                    id="problemsWithConcentration"
                    checked={
                      this.state.psychROS.depressiveSymptoms
                        .problemsWithConcentration
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="problemsWithConcentration"
                  >
                    Problems with concentration
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.weightOrAppetiteChangesLossOrGain"
                    id="weightOrAppetiteChangesLossOrGain"
                    checked={
                      this.state.psychROS.depressiveSymptoms
                        .weightOrAppetiteChangesLossOrGain
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="weightOrAppetiteChangesLossOrGain"
                  >
                    Weight or appetite changes (loss or gain)
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.appetiteChanges"
                    id="appetiteChanges"
                    checked={
                      this.state.psychROS.depressiveSymptoms.appetiteChanges
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="appetiteChanges">
                    Appetite changes
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.psychomotorRetardationActivation"
                    id="psychomotorRetardationActivation"
                    checked={
                      this.state.psychROS.depressiveSymptoms
                        .psychomotorRetardationActivation
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="psychomotorRetardationActivation"
                  >
                    Psychomotor retardation/activation
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="depressiveSymptoms.suicidalIdeation"
                    id="suicidalIdeation"
                    checked={
                      this.state.psychROS.depressiveSymptoms.suicidalIdeation
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="suicidalIdeation">
                    Suicidal ideation
                  </label>
                </div>
                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="depressiveSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      type="textarea"
                      name="depressiveSymptoms.elaborate"
                      id="depressiveSymptoms.elaborate"
                      value={this.state.psychROS.depressiveSymptoms.elaborate}
                      rows={7}
                      className="w-75"
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.manicOrHypomanicSymptoms
                  .manicOrHypomanicSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="manicOrHypomanicSymptoms"
            >
              <label className="bold premium-color">
                <b>Manic or Hypomanic Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.euphoricoodOrExcessiveIrritabilityWithLittleProvocation"
                    id="euphoricoodOrExcessiveIrritabilityWithLittleProvocation"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .euphoricoodOrExcessiveIrritabilityWithLittleProvocation
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="euphoricoodOrExcessiveIrritabilityWithLittleProvocation"
                  >
                    Euphoric mood or excessive irritability with little
                    provocation
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.ideasOfGrandeurOrInflatedSelfesteen"
                    id="ideasOfGrandeurOrInflatedSelfesteen"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .ideasOfGrandeurOrInflatedSelfesteen
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="ideasOfGrandeurOrInflatedSelfesteen"
                  >
                    Ideas of grandeur or inflated self-esteem
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.decreasedNeedForSleepOrFeelingRestedAfterCoupleHrsOfSleep"
                    id="decreasedNeedForSleepOrFeelingRestedAfterCoupleHrsOfSleep"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .decreasedNeedForSleepOrFeelingRestedAfterCoupleHrsOfSleep
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="decreasedNeedForSleepOrFeelingRestedAfterCoupleHrsOfSleep"
                  >
                    Decreased need for sleep or feeling rested after couple hrs.
                    of sleep
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.pressuredSpeechOrHyperverbal"
                    id="pressuredSpeechOrHyperverbal"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .pressuredSpeechOrHyperverbal
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="pressuredSpeechOrHyperverbal"
                  >
                    Pressured speech or hyperverbal
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.racingThoughtsOrFlightOfIdeas"
                    id="racingThoughtsOrFlightOfIdeas"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .racingThoughtsOrFlightOfIdeas
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="racingThoughtsOrFlightOfIdeas"
                  >
                    Racing thoughts or flight of ideas
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.easilyDistractedOnUnimportantOrIrrelevantActivities"
                    id="easilyDistractedOnUnimportantOrIrrelevantActivities"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .easilyDistractedOnUnimportantOrIrrelevantActivities
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="easilyDistractedOnUnimportantOrIrrelevantActivities"
                  >
                    Easily distracted on unimportant or irrelevant activities
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.increasedGoalDirectedActivitiesOrPsychomotorAgitation"
                    id="increasedGoalDirectedActivitiesOrPsychomotorAgitation"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .increasedGoalDirectedActivitiesOrPsychomotorAgitation
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="increasedGoalDirectedActivitiesOrPsychomotorAgitation"
                  >
                    Increased goal directed activities or psychomotor agitation
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="manicOrHypomanicSymptoms.increasedActivityWithHigherPotentialForHarmfulConsequences"
                    id="increasedActivityWithHigherPotentialForHarmfulConsequences"
                    checked={
                      this.state.psychROS.manicOrHypomanicSymptoms
                        .increasedActivityWithHigherPotentialForHarmfulConsequences
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="increasedActivityWithHigherPotentialForHarmfulConsequences"
                  >
                    Increased activity with higher potential for harmful
                    consequences (e.g. buying sprees, sexual indiscretions)
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="manicOrHypomanicSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      type="textarea"
                      name="manicOrHypomanicSymptoms.elaborate"
                      id="manicOrHypomanicSymptoms.elaborate"
                      value={
                        this.state.psychROS.manicOrHypomanicSymptoms.elaborate
                      }
                      rows={7}
                      className="w-75"
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.anxietySymptoms.anxietySymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="anxietySymptoms"
            >
              <label className="bold premium-color">
                <b>Anxiety Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.excessiveAnxietiesAndWorries"
                    id="excessiveAnxietiesAndWorries"
                    checked={
                      this.state.psychROS.anxietySymptoms
                        .excessiveAnxietiesAndWorries
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="excessiveAnxietiesAndWorries"
                  >
                    Excessive anxieties and worries
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.restlessness"
                    id="restlessness"
                    checked={this.state.psychROS.anxietySymptoms.restlessness}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="restlessness">
                    Restlessness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.difficultyConcentrating"
                    id="difficultyConcentrating"
                    checked={
                      this.state.psychROS.anxietySymptoms
                        .difficultyConcentrating
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="difficultyConcentrating"
                  >
                    Difficulty Concentrating
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.irritability"
                    id="irritability"
                    checked={this.state.psychROS.anxietySymptoms.irritability}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="irritability">
                    Irritability
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.muscleTension"
                    id="muscleTension"
                    checked={this.state.psychROS.anxietySymptoms.muscleTension}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="muscleTension">
                    Muscle Tension
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.disruptedSleep"
                    id="disruptedSleep"
                    checked={this.state.psychROS.anxietySymptoms.disruptedSleep}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="disruptedSleep">
                    Disrupted Sleep
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.obsessions"
                    id="obsessions"
                    checked={this.state.psychROS.anxietySymptoms.obsessions}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="obsessions">
                    Obsessions
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.compulsions"
                    id="compulsions"
                    checked={this.state.psychROS.anxietySymptoms.compulsions}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="compulsions">
                    Compulsions
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.ruminations"
                    id="ruminations"
                    checked={this.state.psychROS.anxietySymptoms.ruminations}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="ruminations">
                    Ruminations
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.fearOfBeingJudgedInSocialSituations"
                    id="fearOfBeingJudgedInSocialSituations"
                    checked={
                      this.state.psychROS.anxietySymptoms
                        .fearOfBeingJudgedInSocialSituations
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="fearOfBeingJudgedInSocialSituations"
                  >
                    Fear of being judged in social situations
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.fearOfOpenSpaces"
                    id="fearOfOpenSpaces"
                    checked={
                      this.state.psychROS.anxietySymptoms.fearOfOpenSpaces
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="fearOfOpenSpaces">
                    Fear of open spaces
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietySymptoms.fearOfSpecificthings"
                    id="fearOfSpecificthings"
                    checked={
                      this.state.psychROS.anxietySymptoms.fearOfSpecificthings
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="fearOfSpecificthings"
                  >
                    Fear of specific things (e.g. animals, blood, injections)
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="anxietySymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="anxietySymptoms.elaborate"
                      id="anxietySymptoms.elaborate"
                      value={this.state.psychROS.anxietySymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.panicAttackSymptoms
                  .panicAttackSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="panicAttackSymptoms"
            >
              <label className="bold premium-color">
                <b>Panic Attack Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.palpitationsTachycardia"
                    id="palpitationsTachycardia"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .palpitationsTachycardia
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="palpitationsTachycardia"
                  >
                    Palpitations/tachycardia
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.diaphoresis"
                    id="diaphoresis"
                    checked={
                      this.state.psychROS.panicAttackSymptoms.diaphoresis
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="diaphoresis">
                    Diaphoresis
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.tremulousness"
                    id="tremulousness"
                    checked={
                      this.state.psychROS.panicAttackSymptoms.tremulousness
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="tremulousness">
                    Tremulousness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.shortnessOfBreath"
                    id="shortnessOfBreath"
                    checked={
                      this.state.psychROS.panicAttackSymptoms.shortnessOfBreath
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="shortnessOfBreath">
                    Shortness of breath
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.chokingSensation"
                    id="chokingSensation"
                    checked={
                      this.state.psychROS.panicAttackSymptoms.chokingSensation
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="chokingSensation">
                    Choking Sensation
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.chestPainDiscomfort"
                    id="chestPainDiscomfort"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .chestPainDiscomfort
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="chestPainDiscomfort">
                    Chest pain/discomfort
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.nauseaAbdominalDistress"
                    id="nauseaAbdominalDistress"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .nauseaAbdominalDistress
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="nauseaAbdominalDistress"
                  >
                    Nausea/abdominal distress
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.dizzyLightheadedFaintOrUnsteady"
                    id="dizzyLightheadedFaintOrUnsteady"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .dizzyLightheadedFaintOrUnsteady
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="dizzyLightheadedFaintOrUnsteady"
                  >
                    Dizzy, lightheaded, faint or unsteady
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.derealizationOrDepersonalization"
                    id="derealizationOrDepersonalization"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .derealizationOrDepersonalization
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="derealizationOrDepersonalization"
                  >
                    Derealization or depersonalization
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.fearOfLosingControl"
                    id="fearOfLosingControl"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .fearOfLosingControl
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="fearOfLosingControl">
                    Fear of losing control
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.impendingSenseOfDoomOrDying"
                    id="impendingSenseOfDoomOrDying"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .impendingSenseOfDoomOrDying
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="impendingSenseOfDoomOrDying"
                  >
                    Impending sense of doom or dying
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.paresthesias"
                    id="paresthesias"
                    checked={
                      this.state.psychROS.panicAttackSymptoms.paresthesias
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="paresthesias">
                    Paresthesias
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.chillsOrHeatSensations"
                    id="chillsOrHeatSensations"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .chillsOrHeatSensations
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="chillsOrHeatSensations"
                  >
                    Chills or heat sensations
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="panicAttackSymptoms.avoidanceOfActivitiesForFearOfAnotherEpisode"
                    id="avoidanceOfActivitiesForFearOfAnotherEpisode"
                    checked={
                      this.state.psychROS.panicAttackSymptoms
                        .avoidanceOfActivitiesForFearOfAnotherEpisode
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="avoidanceOfActivitiesForFearOfAnotherEpisode"
                  >
                    Avoidance of activities for fear of another episode
                  </label>
                </div>
                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="panicAttackSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="panicAttackSymptoms.elaborate"
                      id="panicAttackSymptoms.elaborate"
                      value={this.state.psychROS.panicAttackSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.anxietyAdolSymptoms.childAdolCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="anxietyAdolSymptoms"
            >
              <label className="bold premium-color">
                <b>Anxiety Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.excessiveAnxietiesAndWorries"
                    id="anxietyAdolSymptoms.excessiveAnxietiesAndWorries"
                    checked={
                      this.state.psychROS.anxietyAdolSymptoms
                        .excessiveAnxietiesAndWorries
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="anxietyAdolSymptoms.excessiveAnxietiesAndWorries"
                  >
                    Excessive anxieties and worries
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.restlessness"
                    id="anxietyAdolSymptoms.restlessness"
                    checked={this.state.psychROS.anxietyAdolSymptoms.restlessness}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.restlessness">
                    Restlessness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.difficultyConcentrating"
                    id="anxietyAdolSymptoms.difficultyConcentrating"
                    checked={
                      this.state.psychROS.anxietyAdolSymptoms
                        .difficultyConcentrating
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="anxietyAdolSymptoms.difficultyConcentrating"
                  >
                    Difficulty Concentrating
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.irritability"
                    id="anxietyAdolSymptoms.irritability"
                    checked={this.state.psychROS.anxietyAdolSymptoms.irritability}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.irritability">
                    Irritability
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.muscleTension"
                    id="anxietyAdolSymptoms.muscleTension"
                    checked={this.state.psychROS.anxietyAdolSymptoms.muscleTension}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.muscleTension">
                    Muscle Tension
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.disruptedSleep"
                    id="anxietyAdolSymptoms.disruptedSleep"
                    checked={this.state.psychROS.anxietyAdolSymptoms.disruptedSleep}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.disruptedSleep">
                    Disrupted Sleep
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.obsessions"
                    id="anxietyAdolSymptoms.obsessions"
                    checked={this.state.psychROS.anxietyAdolSymptoms.obsessions}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.obsessions">
                    Obsessions
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.compulsions"
                    id="anxietyAdolSymptoms.compulsions"
                    checked={this.state.psychROS.anxietyAdolSymptoms.compulsions}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.compulsions">
                    Compulsions
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.ruminations"
                    id="anxietyAdolSymptoms.ruminations"
                    checked={this.state.psychROS.anxietyAdolSymptoms.ruminations}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.ruminations">
                    Ruminations
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.fearOfBeingJudgedInSocialSituations"
                    id="anxietyAdolSymptoms.fearOfBeingJudgedInSocialSituations"
                    checked={
                      this.state.psychROS.anxietyAdolSymptoms
                        .fearOfBeingJudgedInSocialSituations
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="anxietyAdolSymptoms.fearOfBeingJudgedInSocialSituations"
                  >
                    Fear of being judged in social situations
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.fearOfOpenSpaces"
                    id="anxietyAdolSymptoms.fearOfOpenSpaces"
                    checked={
                      this.state.psychROS.anxietyAdolSymptoms.fearOfOpenSpaces
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.fearOfOpenSpaces">
                    Fear of open spaces
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.fearOfSpecificthings"
                    id="anxietyAdolSymptoms.fearOfSpecificthings"
                    checked={
                      this.state.psychROS.anxietyAdolSymptoms.fearOfSpecificthings
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="anxietyAdolSymptoms.fearOfSpecificthings"
                  >
                    Fear of specific things (e.g. animals, blood, injections)
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.panicAttacks"
                    id="anxietyAdolSymptoms.panicAttacks"
                    checked={this.state.psychROS.anxietyAdolSymptoms.panicAttacks}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.panicAttacks">
                    Panic attacks
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="anxietyAdolSymptoms.fearAndDistress"
                    id="anxietyAdolSymptoms.fearAndDistress"
                    checked={
                      this.state.psychROS.anxietyAdolSymptoms.fearAndDistress
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="anxietyAdolSymptoms.fearAndDistress">
                    Fear and distress when away from home or caregiver
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="anxietyAdolSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="anxietyAdolSymptoms.elaborate"
                      id="anxietyAdolSymptoms.elaborate"
                      value={this.state.psychROS.anxietyAdolSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.pTSDSymptoms.pTSDSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="pTSDSymptoms"
            >
              <label className="bold premium-color">
                <b>PTSD Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="pTSDSymptoms.exposureToDeath"
                    id="exposureToDeath"
                    checked={this.state.psychROS.pTSDSymptoms.exposureToDeath}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="exposureToDeath">
                    Exposure to death or threat of death, serious injury, or
                    sexual violence by direct exposure, witnessing the trauma,
                    learning about it through the exposed individual, or
                    indirect exposure to the negative details during
                    professional duties
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="pTSDSymptoms.traumaIsOftenReExperienced"
                    id="traumaIsOftenReExperienced"
                    checked={
                      this.state.psychROS.pTSDSymptoms
                        .traumaIsOftenReExperienced
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="traumaIsOftenReExperienced"
                  >
                    Trauma is often re-experienced through unwanted memories,
                    nightmare, flashbacks, or emotion and physical reaction to
                    traumatic reminders
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="pTSDSymptoms.traumaAssociatedTriggers"
                    id="traumaAssociatedTriggers"
                    checked={
                      this.state.psychROS.pTSDSymptoms.traumaAssociatedTriggers
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="traumaAssociatedTriggers"
                  >
                    Trauma associated triggers such as thoughts, feelings, or
                    external reminders are avoided
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="pTSDSymptoms.associatedNegativeThoughts"
                    id="associatedNegativeThoughts"
                    checked={
                      this.state.psychROS.pTSDSymptoms
                        .associatedNegativeThoughts
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="associatedNegativeThoughts"
                  >
                    Associated negative thoughts or feelings began or worsened
                    after the trauma such as trouble recalling aspects of the
                    trauma, negative thoughts about oneself or the world, blame
                    of self or others for causing the trauma, negative affects,
                    decreased interests in activities, isolation, and inability
                    to have a positive affect
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="pTSDSymptoms.associatedArousalAndReactivitySymptoms"
                    id="associatedArousalAndReactivitySymptoms"
                    checked={
                      this.state.psychROS.pTSDSymptoms
                        .associatedArousalAndReactivitySymptoms
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="associatedArousalAndReactivitySymptoms"
                  >
                    Associated arousal and reactivity symptoms began or worsened
                    after the trauma such as irritability or aggression, risky
                    or destructive behaviors, hypervigilance, heightened
                    startled reaction, and difficulty concentrating or sleeping
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="pTSDSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="pTSDSymptoms.elaborate"
                      id="pTSDSymptoms.elaborate"
                      value={this.state.psychROS.pTSDSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.psychoticSymptoms.psychoticSymptomsCheck ==
                true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="psychoticSymptoms"
            >
              <label className="bold premium-color">
                <b>Psychotic Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.hallucinations"
                    id="hallucinations"
                    checked={
                      this.state.psychROS.psychoticSymptoms.hallucinations
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="hallucinations">
                    Hallucinations
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.delusions"
                    id="delusions"
                    checked={this.state.psychROS.psychoticSymptoms.delusions}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="delusions">
                    Delusions
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.disorganizedSpeechOrThoughts"
                    id="disorganizedSpeechOrThoughts"
                    checked={
                      this.state.psychROS.psychoticSymptoms
                        .disorganizedSpeechOrThoughts
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="disorganizedSpeechOrThoughts"
                  >
                    Disorganized speech or thoughts
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.disorganizedOrCatatonicBehavior"
                    id="disorganizedOrCatatonicBehavior"
                    checked={
                      this.state.psychROS.psychoticSymptoms
                        .disorganizedOrCatatonicBehavior
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="disorganizedOrCatatonicBehavior"
                  >
                    Disorganized or catatonic behavior
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.lackOfEmotionsOrFacialExpression"
                    id="lackOfEmotionsOrFacialExpression"
                    checked={
                      this.state.psychROS.psychoticSymptoms
                        .lackOfEmotionsOrFacialExpression
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="lackOfEmotionsOrFacialExpression"
                  >
                    Lack of emotions or facial expression
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.lackOfInterestInActivitiesOrSocialEngagement"
                    id="lackOfInterestInActivitiesOrSocialEngagement"
                    checked={
                      this.state.psychROS.psychoticSymptoms
                        .lackOfInterestInActivitiesOrSocialEngagement
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="lackOfInterestInActivitiesOrSocialEngagement"
                  >
                    Lack of interest in activities or social engagement
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.poorHygeine"
                    id="poorHygeine"
                    checked={this.state.psychROS.psychoticSymptoms.poorHygeine}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="poorHygeine">
                    Poor hygeine
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="psychoticSymptoms.lackOfEnergy"
                    id="lackOfEnergy"
                    checked={this.state.psychROS.psychoticSymptoms.lackOfEnergy}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="lackOfEnergy">
                    Lack of energy
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1"
                        for="psychoticSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      type="textarea"
                      name="psychoticSymptoms.elaborate"
                      id="psychoticSymptomsElaborate"
                      rows={7}
                      className="w-75"
                      value={this.state.psychROS.psychoticSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.personalityDisorderSymptoms
                  .personalityDisorderSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="personalityDisorderSymptoms"
            >
              <label className="bold premium-color">
                <b>Personality Disorder Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="personalityDisorderSymptoms.instabilityInEmotionsRelationshipsOrSelfImage"
                    id="instabilityInEmotionsRelationshipsOrSelfImage"
                    checked={
                      this.state.psychROS.personalityDisorderSymptoms
                        .instabilityInEmotionsRelationshipsOrSelfImage
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="instabilityInEmotionsRelationshipsOrSelfImage"
                  >
                    Instability in emotions, relationships, or self image
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="personalityDisorderSymptoms.needToBeTheCenterOfAttention"
                    id="needToBeTheCenterOfAttention"
                    checked={
                      this.state.psychROS.personalityDisorderSymptoms
                        .needToBeTheCenterOfAttention
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="needToBeTheCenterOfAttention"
                  >
                    Need to be the center of attention
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="personalityDisorderSymptoms.feelingNoRemorseWithOnesBehaviorThatViolateOthers"
                    id="feelingNoRemorseWithOnesBehaviorThatViolateOthers"
                    checked={
                      this.state.psychROS.personalityDisorderSymptoms
                        .feelingNoRemorseWithOnesBehaviorThatViolateOthers
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="feelingNoRemorseWithOnesBehaviorThatViolateOthers"
                  >
                    Feeling no remorse with one's behavior that violates others
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="personalityDisorderSymptoms.obsessionWithSuccessPowerBeautyOrLove"
                    id="obsessionWithSuccessPowerBeautyOrLove"
                    checked={
                      this.state.psychROS.personalityDisorderSymptoms
                        .obsessionWithSuccessPowerBeautyOrLove
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="obsessionWithSuccessPowerBeautyOrLove"
                  >
                    Obsession with success, power, beauty, or love
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1 premium-color"
                        for="personalityDisorderSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold premium-color ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      type="textarea"
                      name="personalityDisorderSymptoms.elaborate"
                      id="personalityDisorderSymptoms.elaborate"
                      value={
                        this.state.psychROS.personalityDisorderSymptoms.elaborate
                      }
                      rows={7}
                      className="w-75"
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.neurocognitiveSymptoms
                  .neurocognitiveSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="neurocognitiveSymptoms"
            >
              <label className="bold premium-color">
                <b>Neurocognitive Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="neurocognitiveSymptoms.cognitiveDeclineInLearningAndMemory"
                    id="cognitiveDeclineInLearningAndMemory"
                    checked={
                      this.state.psychROS.neurocognitiveSymptoms
                        .cognitiveDeclineInLearningAndMemory
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="cognitiveDeclineInLearningAndMemory"
                  >
                    Cognitive decline in learning and memory
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="neurocognitiveSymptoms.languageComprehensionAndProductionIssues"
                    id="languageComprehensionAndProductionIssues"
                    checked={
                      this.state.psychROS.neurocognitiveSymptoms
                        .languageComprehensionAndProductionIssues
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="languageComprehensionAndProductionIssues"
                  >
                    Language comprehension and production issues
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="neurocognitiveSymptoms.executiveFunctioningDecline"
                    id="executiveFunctioningDecline"
                    checked={
                      this.state.psychROS.neurocognitiveSymptoms
                        .executiveFunctioningDecline
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="executiveFunctioningDecline"
                  >
                    Executive functioning decline
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="neurocognitiveSymptoms.complexAttentionDeficiency"
                    id="complexAttentionDeficiency"
                    checked={
                      this.state.psychROS.neurocognitiveSymptoms
                        .complexAttentionDeficiency
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="complexAttentionDeficiency"
                  >
                    Complex attention deficiency
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="neurocognitiveSymptoms.perceptualMotorDecline"
                    id="perceptualMotorDecline"
                    checked={
                      this.state.psychROS.neurocognitiveSymptoms
                        .perceptualMotorDecline
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="perceptualMotorDecline"
                  >
                    Perceptual-motor decline
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="neurocognitiveSymptoms.socialCognitionDecline"
                    id="socialCognitionDecline"
                    checked={
                      this.state.psychROS.neurocognitiveSymptoms
                        .socialCognitionDecline
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="socialCognitionDecline"
                  >
                    Social cognition decline
                  </label>
                </div>
                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1 premium-color"
                        for="neurocognitiveSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold premium-color ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="neurocognitiveSymptoms.elaborate"
                      id="neurocognitiveSymptoms.elaborate"
                      value={this.state.psychROS.neurocognitiveSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.disruptiveMoodDysregulationSymptoms
                  .disruptiveMoodDysregulationSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="disruptiveMoodDysregulationSymptoms"
            >
              <label className="bold premium-color">
                <b>Disruptive Mood Dysregulation Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="disruptiveMoodDysregulationSymptoms.persistentIrritableOrAngryMood"
                    id="persistentIrritableOrAngryMood"
                    checked={
                      this.state.psychROS.disruptiveMoodDysregulationSymptoms
                        .persistentIrritableOrAngryMood
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="persistentIrritableOrAngryMood"
                  >
                    Persistent irritable or angry mood
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="disruptiveMoodDysregulationSymptoms.inappropriateTemperOutbursts"
                    id="inappropriateTemperOutbursts"
                    checked={
                      this.state.psychROS.disruptiveMoodDysregulationSymptoms
                        .inappropriateTemperOutbursts
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="inappropriateTemperOutbursts"
                  >
                    Inappropriate temper outbursts
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1 premium-color"
                        for="disruptiveMoodDysregulationSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold premium-color ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="disruptiveMoodDysregulationSymptoms.elaborate"
                      id="disruptiveMoodDysregulationSymptoms.elaborate"
                      value={
                        this.state.psychROS.disruptiveMoodDysregulationSymptoms
                          .elaborate
                      }
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.aDHDSymptoms.aDHDSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="aDHDSymptoms"
            >
              <label className="bold premium-color">
                <b>ADHD Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.persistentLackOfAttentionToDetail"
                    id="persistentLackOfAttentionToDetail"
                    checked={
                      this.state.psychROS.aDHDSymptoms
                        .persistentLackOfAttentionToDetail
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="persistentLackOfAttentionToDetail"
                  >
                    Persistent lack of attention to detail
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.troubleStayingFocused"
                    id="troubleStayingFocused"
                    checked={
                      this.state.psychROS.aDHDSymptoms.troubleStayingFocused
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="troubleStayingFocused"
                  >
                    Trouble staying focused
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.difficultyFollowingInstructions"
                    id="difficultyFollowingInstructions"
                    checked={
                      this.state.psychROS.aDHDSymptoms
                        .difficultyFollowingInstructions
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="difficultyFollowingInstructions"
                  >
                    Difficulty following instructions
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.easyDistractibility"
                    id="easyDistractibility"
                    checked={
                      this.state.psychROS.aDHDSymptoms.easyDistractibility
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="easyDistractibility">
                    Easy distractibility
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.forgetfulness"
                    id="forgetfulness"
                    checked={this.state.psychROS.aDHDSymptoms.forgetfulness}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="forgetfulness">
                    Forgetfulness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.oftenMisplacingPossessions"
                    id="oftenMisplacingPossessions"
                    checked={
                      this.state.psychROS.aDHDSymptoms
                        .oftenMisplacingPossessions
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="oftenMisplacingPossessions"
                  >
                    Often misplacing possessions
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.difficultySustainingMentalEffort"
                    id="difficultySustainingMentalEffort"
                    checked={
                      this.state.psychROS.aDHDSymptoms
                        .difficultySustainingMentalEffort
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="difficultySustainingMentalEffort"
                  >
                    Difficulty sustaining mental effort
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.fidgetiness"
                    id="fidgetiness"
                    checked={this.state.psychROS.aDHDSymptoms.fidgetiness}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="fidgetiness">
                    Fidgetiness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.restlessness"
                    id="aDHDSymptoms.restlessness"
                    checked={this.state.psychROS.aDHDSymptoms.restlessness}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aDHDSymptoms.restlessness"
                  >
                    Restlessness
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.oftenTalksExcessively"
                    id="aDHDSymptoms.oftenTalksExcessively"
                    checked={
                      this.state.psychROS.aDHDSymptoms.oftenTalksExcessively
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aDHDSymptoms.oftenTalksExcessively"
                  >
                    Often talks excessively
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.blurtsOutAnswers"
                    id="aDHDSymptoms.blurtsOutAnswers"
                    checked={this.state.psychROS.aDHDSymptoms.blurtsOutAnswers}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aDHDSymptoms.blurtsOutAnswers"
                  >
                    Blurts out answers
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.interruptsOthers"
                    id="aDHDSymptoms.interruptsOthers"
                    checked={this.state.psychROS.aDHDSymptoms.interruptsOthers}
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aDHDSymptoms.interruptsOthers"
                  >
                    Interrupts others
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.hasTroubleWaitingtheirTurn"
                    id="aDHDSymptoms.hasTroubleWaitingtheirTurn"
                    checked={
                      this.state.psychROS.aDHDSymptoms
                        .hasTroubleWaitingtheirTurn
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aDHDSymptoms.hasTroubleWaitingtheirTurn"
                  >
                    Has trouble waiting their turn
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="aDHDSymptoms.symptomsPresentBeforeAge12"
                    id="aDHDSymptoms.symptomsPresentBeforeAge12"
                    checked={
                      this.state.psychROS.aDHDSymptoms
                        .symptomsPresentBeforeAge12
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aDHDSymptoms.symptomsPresentBeforeAge12"
                  >
                    Symptoms present before age 12
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1 premium-color"
                        for="aDHDSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold premium-color ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="aDHDSymptoms.elaborate"
                      id="aDHDSymptoms.elaborate"
                      value={this.state.psychROS.aDHDSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.otherBehavioralSymptoms
                  .otherBehavioralSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="otherBehavioralSymptoms"
            >
              <label className="bold premium-color">
                <b>Other Behavioral Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.disobedientArgumentativeDefiant"
                    id="disobedientArgumentativeDefiant"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .disobedientArgumentativeDefiant
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="disobedientArgumentativeDefiant"
                  >
                    Disobedient, argumentative, defiant
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.questioningOrRefusalToFollowRules"
                    id="questioningOrRefusalToFollowRules"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .questioningOrRefusalToFollowRules
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="questioningOrRefusalToFollowRules"
                  >
                    Questioning or refusal to follow rules
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.unkindVindictive"
                    id="unkindVindictive"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .unkindVindictive
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label className="form-check-label" for="unkindVindictive">
                    Unkind, vindictive
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.violatesBasicRightsOfOthersOrNormsRules"
                    id="violatesBasicRightsOfOthersOrNormsRules"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .violatesBasicRightsOfOthersOrNormsRules
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="violatesBasicRightsOfOthersOrNormsRules"
                  >
                    Violates basic rights of others or norms/rules
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.aggressionToPeopleAndAnimals"
                    id="aggressionToPeopleAndAnimals"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .aggressionToPeopleAndAnimals
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="aggressionToPeopleAndAnimals"
                  >
                    Aggression to people and animals
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.destructionOfProperty"
                    id="destructionOfProperty"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .destructionOfProperty
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="destructionOfProperty"
                  >
                    Destruction of property
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="otherBehavioralSymptoms.deceitfulOrEngagingInTheftActivities"
                    id="deceitfulOrEngagingInTheftActivities"
                    checked={
                      this.state.psychROS.otherBehavioralSymptoms
                        .deceitfulOrEngagingInTheftActivities
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="deceitfulOrEngagingInTheftActivities"
                  >
                    Deceitful or engaging in theft activities
                  </label>
                </div>
                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1 premium-color"
                        for="otherBehavioralSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold premium-color ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="otherBehavioralSymptoms.elaborate"
                      id="otherBehavioralSymptoms.elaborate"
                      value={
                        this.state.psychROS.otherBehavioralSymptoms.elaborate
                      }
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`col-md-4 ${
                this.state.psychROS.autismSymptoms.autismSymptomsCheck == true
                  ? "form-group show"
                  : "d-none"
              }`}
              id="autismSymptoms"
            >
              <label className="bold premium-color">
                <b>Autism Symptoms</b>
              </label>
              <div className="ml-4">
                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="autismSymptoms.deficitsInSocialEmotionalReciprocity"
                    id="deficitsInSocialEmotionalReciprocity"
                    checked={
                      this.state.psychROS.autismSymptoms
                        .deficitsInSocialEmotionalReciprocity
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="deficitsInSocialEmotionalReciprocity"
                  >
                    Deficits in social-emotional reciprocity
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="autismSymptoms.deficitsInNonverbalCommunicationBehaviors"
                    id="deficitsInNonverbalCommunicationBehaviors"
                    checked={
                      this.state.psychROS.autismSymptoms
                        .deficitsInNonverbalCommunicationBehaviors
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="deficitsInNonverbalCommunicationBehaviors"
                  >
                    Deficits in nonverbal communication behaviors
                  </label>
                </div>

                <div>
                  <input
                    className="form-check-input customize-disabled-checkbox"
                    type="checkbox"
                    name="autismSymptoms.deficitsInDevelopingMaintainingAndUnderstandingRelationships"
                    id="deficitsInDevelopingMaintainingAndUnderstandingRelationships"
                    checked={
                      this.state.psychROS.autismSymptoms
                        .deficitsInDevelopingMaintainingAndUnderstandingRelationships
                    }
                    onChange={(event) => this.handleCheckboxChange(event)}
                  />
                  <label
                    className="form-check-label"
                    for="deficitsInDevelopingMaintainingAndUnderstandingRelationships"
                  >
                    Deficits in developing, maintaining, and understanding
                    relationships
                  </label>
                </div>

                <div>
                  <div className="d-flex justify-content-between w-75">
                    <div>
                      <label
                        className="bold pb-2 pt-1 premium-color"
                        for="autismSymptoms.elaborate"
                      >
                        Elaborate:
                      </label>
                    </div>
                    <span className="bold premium-color ai-suggestion">AI</span>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="w-75"
                      rows={7}
                      type="textarea"
                      name="autismSymptoms.elaborate"
                      id="autismSymptoms.elaborate"
                      value={this.state.psychROS.autismSymptoms.elaborate}
                      onChange={(event) => this.handleInputChange(event)}
                      onBlur={(event) => this.handleInputChange(event)}
                      onKeyUp={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default class PsychROS extends ReactComponent {
  /**
   * This function tells the form builder about your component. It's name, icon and what group it should be in.
   *
   * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
   */

  static get builderInfo() {
    return {
      title: "Psych Review of System",
      icon: "medkit",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: PsychROS.schema(),
    };
  }

  /**
   * This function is the default settings for the component. At a minimum you want to set the type to the registered
   * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
   *
   * @param sources
   * @returns {*}
   */
  static schema() {
    return ReactComponent.schema({
      type: "psychROSComp",
    });
  }

  /*
   * Defines the settingsForm when editing a component in the builder.
   */
  static editForm = settingsForm;

  /**
   * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
   *
   * @param DOMElement
   * #returns ReactInstance
   */
  attachReact(element) {
    return ReactDOM.render(
      <PsychROSComp
        component={this.component} // These are the component settings if you want to use them to render the component.
        value={this.dataValue} // The starting value of the component.
        onChange={this.updateValue} // The onChange event to call when the value changes.
        ref={(comp) => (this._component = comp)}
      />,
      element
    );
  }

  /**
   * Automatically detach any react components.
   *
   * @param element
   */
  detachReact(element) {
    if (element) {
      ReactDOM.unmountComponentAtNode(element);
    }
  }

  getValue() {
    return this._component.getValue();
  }

  setValue(value) {
    this._component.setValue(value);
  }
}