import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./AssessmentToolTabular.settingsForm";

export default class AssessmentToolTabular extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Tabular Questionnaire Widget",
      icon: "dot-circle-o",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: AssessmentToolTabular.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "assessmentToolTabularComp",
      radioButtonLabel: "",
      groupName: "",
      radioButtonsColumns: [],
      requiredSaveNote: "",
      addTooltip: ""
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Tabular Questionnaire Widget");
    $(".datagrid-table th").each(function(index) {
      if(index==0){
        $(this).html("Order");
      }       
      
    });
    let table_title = "";
    let table_group_name = "";

    let radio_label = `${this.component.radioButtonLabel || ''}`;

        var tableClass = `table-plain w-100`;

     let content = '';
    let value = '';
    let readonly = false
    if (this.component.radioButtonsColumns){
      for (let i = 0; i < this.component.radioButtonsColumns.length; i++) {
      let row = '<tr>';
      let cell = '<td class="text-left">';
       cell +=  `<div class="form-group">`;
        value = this.component.radioButtonsColumns[i].radioWeight
          cell += `<label for="${this.component.key}-${i}" class="form-check-label">`;

          var attr_json = {
                name: `${this.component.key}`,
                id: `${this.component.key}-${i}`,
                class: `${this.component.groupName}`,
                value: value,
                type: this.component.radioButtonsColumns[i].ColumnType,
              }

              if (this.component.requiredField  || this.component.requiredField == undefined){
                  attr_json.required = 'required'
                }
          cell += this.renderTemplate('input', {
            input: {
              type: 'input',
              ref: `${this.component.key}-${i}`,
              groupname: this.component.groupName,
              attr: attr_json
            }
          });
         cell +=  this.component.radioButtonsColumns[i].radioButtonText
          cell += `</label>`;
          cell += `</div>`;
        cell += '</td>';
        row += cell;
        row += '</tr>';
      content += row;
    }
  }

    return super.render(`
      <div id="${this.component.key}" class="${this.component.requiredField ? 'required-component-table' : ''}">
         <div class="table-responsive">
          <table class="${tableClass}">
            <thead class="table-plain"><tr><th>${radio_label}</th></tr></thead>
            <tbody>
              ${content}
            </tbody>
          </table>
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    for (let i = 0; i < this.component.radioButtonsColumns.length; i++) {
      refs[`${this.component.key}-${i}`] = '';
    }

    this.loadRefs(element, refs);

    for (let i = 0; i < this.component.radioButtonsColumns.length; i++) {
      this.addEventListener(this.refs[`${this.component.key}-${i}`][0], 'click', () => this.updateValue())
    }

    return super.attach(element);
  }

  getValue() {
    var value = [];
    value[0] = [];
    for (let i = 0; i < this.component.radioButtonsColumns.length; i++) {
      var input = this.refs[`${this.component.key}-${i}`];

      value[0][i] = input[0].value;
      // if(value[0][i]){
      //   $(`input#${this.component.key}-${i}`).attr("checked", "checked");
      // }
      // else{
      //   $(`input#${this.component.key}-${i}`).removeAttr("checked");
      // }
      if (input[0].type == 'text'){
        $(`input#${this.component.key}-${i}`).attr("value", value[0][i]);

      } else {
        if (input[0].checked){
          $(`input#${this.component.key}-${i}`).prop("checked", input[0].checked);
          $(`input#${this.component.key}-${i}`).attr("data-id", 'checked');
        } else {
          value[0][i] = input[0].checked;
          $(`input#${this.component.key}-${i}`).prop("checked", value[0][i]);
          $(`input#${this.component.key}-${i}`).prop("data-id", 'unchecked');

        }
      }
    }
    
    
    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    for (let i = 0; i < this.component.radioButtonsColumns.length; i++) {
      let checked = value[0][i] ? 1 : 0;
      this.refs[`${this.component.key}-${i}`][0].value = checked;
      this.refs[`${this.component.key}-${i}`][0].checked = checked;
      if(checked){
        $(`input#${this.component.key}-${i}`).attr("checked", "checked");
      }
      else{
        $(`input#${this.component.key}-${i}`).removeAttr("checked");
      }
    }
    
   
  }

}
