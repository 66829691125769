import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          { key: "tooltip", ignore: true }, 
          { key: "description", ignore: true },   
          {
            type: "textfield",
            input: true,
            label: "Label (Optional)",
            weight: 1,
            key: "medicationLabel",
            placeholder: "Label"
          }       
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
