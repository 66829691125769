import labelCustomComp from "./Label";
import headingCustomComp from "./Heading";
import hiddenFieldCustomComp from "./HiddenField";
import textFieldCustomComp from "./TextField";
import textAreaCustomComp from "./TextArea";
import dividerCustomComp from "./Divider";
import spacerCustomComp from "./Spacer";
import explanatoryTextCustomComp from "./ExplanatoryText";
import checkBoxesCustomComp from "./CheckBoxes";
import radioButtonsCustomComp from "./RadioButtons";
import tableCustomComp from "./Table";
import emailCustomComp from "./Email";
import phoneCustomComp from "./Phone";
import sectionCustomComp from "./Section";
import bloodPressureComp from "./BloodPressure";
import datetimeComp from "./DateTime";
import drugAllergiesComp from "./DrugAllergies";
import immunizationComp from "./Immunization";
import medicalDiagnosisComp from "./MedicalDiagnosis";
import medicationComp from "./Medication";
import medicationListComp from "./MedicationList";
import nonDrugAllergiesComp from "./NonDrugAllergies";
import activeGoalsComp from "./ActiveGoals";
import achievedGoalsComp from "./AchievedGoals";
import progressTowardsGoalsComp from "./ProgressTowardsGoals";
import problemsComp from "./Problems";
import proceduresComp from "./Procedures";
import psychDiagnosisComp from "./PsychDiagnosis";
import signatureComp from "./Signature";
import udiComp from "./Udi";
import vitalSignsComp from "./VitalSigns";
import checkmatrix from "./CheckMatrix";
import revisedGoalsComp from "./RevisedGoals";
import discontinuedGoalsComp from "./DiscontinuedGoals";
import deferredGoalsComp from "./DeferredGoals";
import demographicComp from "./Demographic";

//My imports
import presentIllnessComp from './HistoryOfPresentIllness';
import assessmentsAndPlansComp from "./AssessmentsAndPlans";
import mentalStatusExamComp from "./MentalStatusExam";
import pastInpatientComp from "./PastInpatientPsychiatricHistory";
import pastOutpatientComp from "./PastOutpatientPsychiatricHistory";
import psychROSComp from "./PsychROS";
import goalsComp from './Goals';
import assessmentToolComp from './AssessmentTool';
import weightedCheckboxesComp from './WeightedCheckboxes';
import weightedScoreComp from './WeightedScore';
import GroupScoreComp from './GroupScore';
import assessmentToolTabularComp from './AssessmentToolTabular';




export default {
  labelCustomComp,
  headingCustomComp,
  hiddenFieldCustomComp,
  textFieldCustomComp,
  textAreaCustomComp,
  dividerCustomComp,
  spacerCustomComp,
  explanatoryTextCustomComp,
  checkBoxesCustomComp,
  radioButtonsCustomComp,
  tableCustomComp,
  emailCustomComp,
  phoneCustomComp,
  sectionCustomComp,
  bloodPressureComp,
  datetimeComp,
  drugAllergiesComp,
  immunizationComp,
  medicalDiagnosisComp,
  medicationComp,
  medicationListComp,
  nonDrugAllergiesComp,
  activeGoalsComp,
  achievedGoalsComp,
  revisedGoalsComp,
  discontinuedGoalsComp,
  deferredGoalsComp,
  progressTowardsGoalsComp,
  problemsComp,
  proceduresComp,
  psychDiagnosisComp,
  signatureComp,
  udiComp,
  vitalSignsComp,
  checkmatrix,
  demographicComp,
  presentIllnessComp,
  assessmentsAndPlansComp,
  mentalStatusExamComp,
  pastInpatientComp,
  pastOutpatientComp,
  psychROSComp,
  goalsComp,
  assessmentToolComp,
  weightedCheckboxesComp,
  weightedScoreComp,
  GroupScoreComp,
  assessmentToolTabularComp
};
