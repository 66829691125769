import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./AssessmentTool.settingsForm";

export default class AssessmentTool extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Weighted Questionnaire Widget",
      icon: "table",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: AssessmentTool.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "assessmentToolComp",
      title: "",
      titleAlign: "text-left",
      tableColumns: [],
      tableRows: [],
      requiredSaveNote: false,
      addTooltip: false,
      tooltipDescription: ""
    });
  }

  static editForm = settingsForm;

  render(children) {
   $('.formio-choices.form-group').css("min-width", "100px");
    $(".component-edit-container p.lead").html("Weighted Questionnaire Widget");
    $(".datagrid-table th").each(function(index) {
      if(index==2){
        $(this).css("width", "20px");
      }
      if(index==3){
        $(this).css("width", "50px");
      }  
       if(index==5){
        $(this).html("Remove");
      }      
      
    });
    // // For changing column sr no index start
    // $.each($('input').filter('[name ^="data[tableColumns]"][name $="[srNo]"]').get(), function( index, input ) {
    //   $(this).val(index + 1);
    //   $(this).attr("readonly", true);
    // });
    // For changing column sr no index end

    let table_title = "";
    let table_group_name = "";

    table_title += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required1' : ''}">`;
    table_title += `${this.component.title || ''}`;
    table_title += `</span>`;

    table_group_name += `<span class="d-none">`;
    table_group_name += `${this.component.groupName || ''}`;
    table_group_name += `</span>`;

    if (this.component.addTooltip && this.component.tooltipDescription){
      table_title += ` <i class="fa fa-question-circle text-muted" ref="tooltip" data-title="${this.component.tooltipDescription}"></i>`;
    }

    let tableClass = 'table table-bordered table-striped';
    ['striped', 'bordered', 'hover', 'condensed'].forEach((prop) => {
      if (this.component[prop]) {
        tableClass += `table-${prop} `;
      }
    });

    let table_head = '<thead><tr>';

    let totalColumnWidth = 0 ;
    let inputFocus = '' ;
    let inputFocusValue = '' ;
    let col_index = 0;
    for (let j = 0; j < this.component.tableColumns.length; j++) {
      $(`[name="data[tableColumns][${j}][columnWidth]"]`).attr("value",this.component.tableColumns[j].columnWidth);
      if (this.component.tableColumns[j].columnWidth > 0){
        totalColumnWidth += parseFloat($(`[name="data[tableColumns][${j}][columnWidth]"]`).val()) || 0;
      }
      let columnWidth = this.component.tableColumns[j].columnWidth
      if ($(`[name="data[tableColumns][${j}][columnWidth]"]`).is(":focus")) {
        inputFocus = `[name="data[tableColumns][${j}][columnWidth]"]`;
        col_index = j
        inputFocusValue= $(inputFocus).val();
      }
      if (totalColumnWidth < 100){
        table_head += `<th class="text-center" width='${columnWidth}%'>${this.component.tableColumns[j].Label}</th>`;
      }
      else{
        table_head += `<th>${this.component.tableColumns[j].Label}</th>`;
      }
    }
     for (let j = 0; j < this.component.tableColumns.length; j++) {
      $(`[name="data[tableColumns][${j}][columnWidth]"]`).removeClass('error');
     }
    if (totalColumnWidth > 100){
      // this.component.tableColumns[col_index].columnWidth = 0;
      $(inputFocus).attr("value",'');
      $(inputFocus).val('');
      $(inputFocus).addClass('error');
    }

    table_head += '</tr></thead>';

    let content = '';
    let value = '';
    let readonly = false
    if (this.component.tableRows){
      for (let i = 0; i < this.component.tableRows.length; i++) {
      let row = '<tr>';
      for (let j = 0; j < this.component.tableColumns.length; j++) {
        let cell = '<td class="text-left">';

        if(j == 0){
          readonly = false
          cell += `<span class="text-left">`;
          cell += `${i+1}. ${ this.component.tableRows[i].Label || ''}`;
          cell += `</span>`;

        } else {
          value = this.component.tableColumns[j].radioWeight
          readonly = false
          var attr_json = {
                    name: `${this.component.key}-${this.component.id}-${i}`,
                    id: `${this.component.key}-${this.component.id}-${i}-${j}`,
                    class: `line-input w-100 questionnaire ${this.component.groupName}`,
                    type: this.component.tableColumns[j].ColumnType,
                    placeholder: `${this.component.tableColumns[j].radioButton}`,
                    value: value,
                    group: this.component.groupName,
                  }
                if (this.component.tableRows[i].requiredField == 'required' || this.component.tableRows[i].requiredField == undefined){
                  attr_json.required = 'required'
                }

          cell += this.renderTemplate('input', {
            input: {
              type: 'input',
              ref: `${this.component.key}-${this.component.id}-${i}-${j}`,
              groupname: this.component.groupName,
              attr: attr_json
            }
          });
        }

        
        

        cell += '</td>';
        row += cell;
      }
      row += '</tr>';
      content += row;
    }
    }
    

    return super.render(`
      <div id="${this.component.key}-${this.component.id}" class="${this.component.id} ${this.component.requiredSaveNote ? 'required-component-table' : ''}">
        <div class="${this.component.titleAlign}">
          ${table_title}
          ${table_group_name}
        </div>
        <div class="table-responsive">
          <table class="${tableClass}">
            ${table_head}
            <tbody>
              ${content}
            </tbody>
          </table>
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};
    if (this.component.tableRows){
      for (let i = 0; i < this.component.tableRows.length; i++) {
        for (let j = 0; j < this.component.tableColumns.length; j++) {
        refs[`${this.component.key}-${this.component.id}-${i}-${j}`] = '';
        }
      }
      // for (let i = 0; i < this.component.tableRows.length; i++) {
      //   refs[`${this.component.key}-${i}`] = '0';
      // }
    }

    this.loadRefs(element, refs);

    if (this.component.tableRows){
      for (let i = 0; i < this.component.tableRows.length; i++) {
        for (let j = 0; j < this.component.tableColumns.length; j++) {
          this.addEventListener(this.refs[`${this.component.key}-${this.component.id}-${i}-${j}`][0], 'click', () => this.updateValue())
        }
      }
    }
    // this.checks = [];
    // if (this.component.tableRows){
    //   for (let i = 0; i < this.component.tableRows.length; i++) {
    //       this.checks[i] = Array.prototype.slice.call(this.refs[`${this.component.key}-${i}`], 0);
    //       this.checks[i].forEach(input => {
    //         this.addEventListener(input, 'keyup', () => this.updateValue())
    //         this.addEventListener(this.refs[`${this.component.key}-${i}`][0], 'click', () => this.updateValue())
    //       });
    //   }
    // }
    return super.attach(element);
  }

  getValue() {
     var value = [];
     // value[0] = [];
     // value[0][0] = [];
     for (let i = 0; i < this.component.tableRows.length; i++) {
        for (let j = 0; j < (this.component.tableColumns.length); j++) {
          var input = this.refs[`${this.component.key}-${this.component.id}-${i}-${j}`];
          if (input[0] && input[0].type == 'text'){
            value[i] = [];
            value[i][j] = '';
          } else {
             value[i] = [];
            value[i][j] = [];
          }
        }
      }

    // for (var rowIndex in this.checks) {
    //   var row = this.checks[rowIndex];
    //   value[rowIndex] = [];
    //   for (var colIndex in row) {
    //     var col = row[colIndex];
    //     if (col.type == "text"){
    //       value[rowIndex][colIndex] = col.value;
    //     } else {
    //       if (col.checked){
    //         value[rowIndex][colIndex] = col.value;
    //       } else {
    //         value[rowIndex][colIndex] = col.checked;
    //       }
    //     }
    //     $('#'+col.id).attr("value",value[rowIndex][colIndex]); // This is for export PDF
    //   }
    // }
    // return value;
    if (this.component.tableRows){
      for (let i = 0; i < this.component.tableRows.length; i++) {
        for (let j = 0; j < this.component.tableColumns.length; j++) {
          var input = this.refs[`${this.component.key}-${this.component.id}-${i}-${j}`];

          if (input && input[0]){
            value[i][j] = input[0].value;

            if (input[0].type == 'text'){
              // console.log("Text Input Value",value[i][j] )
              $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).attr("value", value[i][j]);

            } else {
              if (input[0].checked){
                $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).prop("checked", input[0].checked);
                $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).attr("data-id", 'checked');
              } else {
                value[i][j] = input[0].checked;
                $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).prop("checked", value[i][j]);
                $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).prop("data-id", 'unchecked');

              }
            }
           
          }
        }
      }
    }
   return value;

  }

  setValue(value) {
    // if (!value) {
    //   return;
    // }
    // for (var rowIndex in this.checks) {
    //   var row = this.checks[rowIndex];
    //   if (!value[rowIndex]) {
    //     break;
    //   }
    //   for (var colIndex in row) {
    //     var col = row[colIndex];

    //     if (col.type == "text"){
    //         col.value = value[rowIndex][colIndex];
    //     } else {
    //       if (value[rowIndex][colIndex]){
    //           col.checked = true;
    //       } else {
    //           col.checked = false;
    //       }
    //     }

    //     $('#'+col.id).attr("value",value[rowIndex][colIndex]); // This is for export PDF
    //   }
    // }
    console.log('value', value)
      if (!value) {
        return;
      }
      if (this.component.tableRows){
        for (let i = 0; i < this.component.tableRows.length; i++) {
          for (let j = 0; j < this.component.tableColumns.length; j++) {
            if (j === 0) { continue; }
            var input = this.refs[`${this.component.key}-${this.component.id}-${i}-${j}`];
            // console.log(`value ${this.component.key}-${this.component.id}-${i}-${j}`, value[i][j])
            if (input && input[0]){
              if (input[0].type == 'text'){
                input[0].value = value[i][j-1];
              } else {
                if(value[i][j-1]){
                  input[0].checked = true;
                  $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).prop("checked",true);
                  $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).attr("data-id",'checked');
                } else {
                  input[0].checked = false;
                  $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).prop("checked",false);
                  $(`input#${this.component.key}-${this.component.id}-${i}-${j}`).attr("data-id",'unchecked');

                }
              }  
            }
          }
        }
      }

  }

}
