document.write(
  unescape("%3Cscript src='https://cdn.ckeditor.com/4.14.0/standard/ckeditor.js' type='text/javascript'%3E%3C/script%3E")
);

document.addEventListener("turbolinks:load", function() {
  $('.selectpicker').selectpicker();
	var pTable = $('.dt-sort-p').DataTable({
    pageLength: 25,
    responsive: true,
    "order":[[0, 'asc']],
    dom: '<"html5buttons"B><"topbar"lp>Tgit',
    buttons: [
    ],
    columnDefs: [
      { targets: 'select-all-col', orderable: false },
      { targets: 'form-cell', visible: false, orderable: false}
    ]
  });

  var rankTable = $('.dt-sort-rank').DataTable({
    pageLength: 25,
    responsive: true,
    "order":[[1, 'asc']],
    dom: '<"html5buttons"B><"topbar"lp>Tgit',
    buttons: [
    ],
    columnDefs: [
      { targets: 'select-all-col', orderable: false },
      { targets: 'form-cell', visible: false, orderable: false}
    ]
  });

  var rankTable = $('.dt-sort-taxonomy-codes').DataTable({
    pageLength: 25,
    responsive: true,
    "order": false,
    dom: '<"html5buttons"B><"topbar"lp>Tgit',
    buttons: [
    ],
    columnDefs: [
      { targets: 'select-all-col', orderable: false },
      { targets: 'form-cell', visible: false, orderable: false}
    ]
  });

  $('.user-search-p').keyup(function(){
    pTable.search($(this).val()).draw();
    rankTable.search($(this).val()).draw();
  });

  $('.select2').select2();
  
  $('[data-toggle="tooltip"]').tooltip()

});

document.addEventListener("turbolinks:before-cache", function() {
  $('.dt-sort-p').DataTable().destroy();
});
