import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "description",
        label: "Description",
        components: [
          {
            type: "textfield",
            input: true,
            label: "<p>This widget allows you to include the established unique device identification (UDI) system to adequately identify medical devices to your form.  The widget includes the Device Name, Date Implemented, UDI code information, Status, and GUDID Attributes.  You can also further manage the client's UDI information (add, remove, or change) through the widget.</p><p><em>Please note that we recommend that you place this widget within any layout with a width of 3 or more segments due to space and formatting constraints.</em></p>",
            weight: 1,
            customClass: "hide-input"
          }
        ]
      },
      {
        key: "display",
        ignore: true
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
