import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend) => {
  return baseEditForm(
    [
      {
        key: "display",
        label: "Settings",
        components: [
          { key: "label", ignore: true },
          { key: "placeholder", ignore: true },
          { key: "labelPosition", ignore: true },
          { key: "description", ignore: true },
          { key: "tooltip", ignore: true },
          { key: "customClass", ignore: true },
          { key: "tabindex", ignore: true },
          { key: "hidden", ignore: true },
          { key: "hideLabel", ignore: true },
          { key: "autofocus", ignore: true },
          { key: "disabled", ignore: true },
          { key: "tableView", ignore: true },
          { key: "modalEdit", ignore: true },
          {
            // Or add your own. The syntax is form.io component definitions.
            type: "textfield",
            input: true,
            label: "Text",
            weight: 12,
            key: "text" // This will be available as component.myCustomSetting
          },
          {
            type: "select",
            label: "Text Alignment",
            weight: 13,
            key: "textAlign",
            data: {
              values: [
                {value: 'text-left', label: 'Left'},
                {value: 'text-center', label: 'Center'},
                {value: 'text-right', label: 'Right'}
              ]
            }
          },
          {
            type: "select",
            label: "Text Size",
            weight: 14,
            key: "textSize",
            data: {
              values: [
                {value: 'h1', label: 'Extra Large'},
                {value: 'h2', label: 'Large'},
                {value: 'h3', label: 'Medium'},
                {value: 'h4', label: 'Small'},
                {value: 'h5', label: 'Extra Small'}
              ]
            }
          },
          {
            type: "radio",
            label: "Color Theme",
            weight: 14,
            key: "colorTheme",
            values: [
              {value: 'bg-white', label: 'White'},
              {value: 'bg-lightgray', label: 'Light Gray'}
            ]
          },
          {
            type: "checkbox",
            label: "Bold",
            weight: 14,
            key: "textBold",
            tooltip: "Heading will be displayed as bold.",
            value: "text-bold"
          }
          ,
          {
            type: "checkbox",
            label: "Italic",
            weight: 14,
            key: "textItalic",
            tooltip: "Heading will be displayed as italic.",
            value: "text-italic"
          },
          {
            type: "checkbox",
            label: "Underline",
            weight: 14,
            key: "textUnderline",
            tooltip: "Heading will be displayed as underlined.",
            value: "text-underline"
          }
        ]
      },
      {
        key: "data",
        ignore: true
      },
      {
        key: "validation",
        ignore: true
      },
      {
        key: "api",
        ignore: true
      },
      {
        key: "conditional",
        ignore: true
      },
      {
        key: "logic",
        ignore: true
      },
      {
        key: "layout",
        ignore: true
      }
    ],
    ...extend
  );
};
