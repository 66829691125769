import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactComponent from 'formiojs/components/_classes/component/Component';
import settingsForm from "./WeightedCheckboxes.settingsForm";

export default class WeightedCheckboxes extends ReactComponent {

  static get builderInfo() {
    return {
      title: "Weigthed Checkboxes",
      icon: "check-square",
      group: "Data",
      documentation: "",
      weight: -10,
      schema: WeightedCheckboxes.schema()
    };
  }

  static schema() {
    return ReactComponent.schema({
      type: "weightedCheckboxesComp",
      checkBoxLabel: "",
      checkBoxLayout: "form-check",
      checkBoxesColumns: [],
      requiredSaveNote: false,
      addTooltip: false,
      tooltipDescription: ""
    });
  }

  static editForm = settingsForm;

  render(children) {

    $(".component-edit-container p.lead").html("Weigthed Checkboxes");
    $(".datagrid-table th").each(function(index) {
         
      if(index==1){
        $(this).css("width", "40px");
      }
    });
    let checkbox_label = "";
    checkbox_label += `<span class="comp-label ${this.component.requiredSaveNote ? 'field-required' : ''}">`;
    checkbox_label += `${this.component.checkBoxLabel || ''}`;
    checkbox_label += `</span>`;
    if (this.component.addTooltip && this.component.tooltipDescription){
      checkbox_label += ` <i class="fa fa-question-circle text-muted" ref="tooltip" data-title="${this.component.tooltipDescription}"></i>`;
    }

    let content = `<div class="form-group">`;
    let other = '';

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      let cell = `<div class="${this.component.checkBoxLayout}">`;
      cell += `<label for="${this.component.key}-${i}" class="form-check-label">`;
      cell += this.renderTemplate('input', {
        input: {
          type: 'input',
          ref: `${this.component.key}-${i}`,
          attr: {
            id: `${this.component.key}-${i}`,
            class: `form-check-input ${this.component.requiredSaveNote ? 'required' : ''}`,
            type: 'checkbox',
            value: this.component.checkBoxesColumns[i].checkBoxWeight,
          }
        }
      });
      cell += `<span>${this.component.checkBoxesColumns[i].checkBoxText}</span>`;
      cell += `</label>`;
      cell += `</div>`;
      content += cell;
    }

    if (this.component.includeOther){
      other += `<div class="${this.component.checkBoxLayout}">`;
      other += `<label for="${this.component.key}-checkbox-other" class="form-check-label">`;
      other += this.renderTemplate('input', {
        input: {
          type: 'input',
          ref: `${this.component.key}-checkbox-other`,
          attr: {
            id: `${this.component.key}-checkbox-other`,
            class: `form-check-input checkBoxesCustomComp-checkbox-other ${this.component.requiredSaveNote ? 'required' : ''}`,
            type: 'checkbox',
          }
        }
      });
      other += `<span>Other</span>`;
      other += `</label>`;
      other += this.renderTemplate('input', {
        input: {
          type: 'input',
          ref: `${this.component.key}-other`,
          attr: {
            id: `${this.component.key}-other`,
            class: 'line-input form-check-input-other',
            type: 'text',
          }
        }
      });
      other += `</div>`;
      content += other;
    }

    content += `</div>`;

    return super.render(`
      <div id="${this.component.key}" class="${this.component.requiredSaveNote ? 'required-component-checkboxes' : ''}">
        <div>
          ${checkbox_label}
        </div>
        <div class="form-radio radio">
          ${content}
        </div>
      </div>
    `);
  }


  attach(element) {
    const refs = {};

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      refs[`${this.component.key}-${i}`] = '';
    }
    this.loadRefs(element, refs);

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      this.addEventListener(this.refs[`${this.component.key}-${i}`][0], 'change', () => this.updateValue())
    }

    return super.attach(element);
  }

  getValue() {
    var value = [];
    value[0] = [];
    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      value[0][i] = this.refs[`${this.component.key}-${i}`][0].checked;
      $(`input#${this.component.key}-${i}`).prop("checked", this.refs[`${this.component.key}-${i}`][0].checked);
    }

    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      if (this.refs[`${this.component.key}-${i}`][0].checked){
        value[0][i] = this.refs[`${this.component.key}-${i}`][0].value;
      }
    }

    return value;
  }

  setValue(value) {
    if (!value) {
      return;
    }
    for (let i = 0; i < this.component.checkBoxesColumns.length; i++) {
      this.refs[`${this.component.key}-${i}`][0].checked = value[0][i];
      $(`input#${this.component.key}-${i}`).prop("checked", value[0][i]);
    }
  }
}
